import _get from 'lodash/get';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import Types from '../actions/types';
import accessTokenService from '../services/access-token-service';
import markitApi from '../services/markit-api-service';
import { getApiTypes } from './getApiTypes';
import { onGenericApiFailure, onGenericApiSuccess } from '../actions/commonActions';

// Use a 'watcher/worker' pattern to listen for and handle redux Actions
function MarkitDataSaga() {
  const actionsToWatch = getApiTypes();
  let api = null;
  let url = null;

  // State Selectors
  const getMarkitSession = (state) => state.sessionData.markit;

  function getApiFunction(httpMethod) {
    let apiType;

    switch (httpMethod) {
      case 'POST':
        apiType = api.postData;
        break;
      case 'PUT':
        apiType = api.putData;
        break;
      case 'DELETE':
        apiType = api.deleteData;
        break;
      case 'GET':
      default:
        apiType = api.getData;
    }

    return apiType;
  }

  // Worker
  function* worker(action) {
    const markitSession = yield select(getMarkitSession);

    if (!api) {
      const token = markitSession.accessToken || accessTokenService.retrieve();
      const isDev = ['development', 'localhost'].indexOf(window.MD.environment) > -1;
      url = window.MD.apiBaseUrl;

      if ((isDev || token) && url) {
        api = markitApi.create(url, token);
      }
    }

    if (api) {
      let endPoint = action.endPoint;
      let parameters = action.params;
      let successAction = action.onSuccess || onGenericApiSuccess;
      let failureAction = action.onFailure || onGenericApiFailure;
      let httpMethod = action.ajaxType;

      //To prevent caching issue in IE11
      if (httpMethod === 'GET' && !!window.navigator.msSaveOrOpenBlob && !!document.documentMode) {
        endPoint =
          endPoint.indexOf('?') > -1
            ? endPoint + '&' + new Date().getTime()
            : endPoint + '?' + new Date().getTime();
      }
      let apiType = getApiFunction(httpMethod);

      try {
        let response;
        if (action.type === Types.AUTHENTICATION_CLOSE_SESSION) {
          api = undefined;
          response = { data: {}, ok: true };
        } else if (action.type === Types.GET_URLBASE_API) {
          response = { data: { url: url }, ok: true };
        } else if (action.type === Types.API_REQUEST_DECODE_API) {
          response = yield parameters.key.map((p) => call(apiType, { endPoint, params: p }));
        } else {
          response = yield call(apiType, { endPoint, params: parameters });
        }

        // de-nesting results
        response.data = _get(response, 'data.data') || response.data;

        Object.assign({}, response.data.data) || Object.assign({}, response.data);

        // Logs state updates when ShowDebugInfo is enabled
        if (window.location.href.indexOf('..showdebuginfo..=on') > -1) {
          console.log('saga-action-response', JSON.stringify(response));
        }

        if (response.ok) {
          yield put(successAction(response));
        } else if ([401, 403].indexOf(response.status) > -1) {
          api = undefined;
          put({ type: Types.AUTHENTICATION_CLOSE_SESSION });
        } else {
          yield put(failureAction(response));
        }
      } catch (error) {
        yield put(failureAction(error));
      }
    }
  }

  // Watcher
  function* watcher() {
    yield takeEvery(actionsToWatch, worker);
  }

  return {
    watcher
  };
}

export default MarkitDataSaga;
