/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import ImageThumbnail from '../common/ImageThumbnail'
import { HeadLineTitle, HeadLineSubject } from '../common/Headline'
import { Row, Col } from 'react-bootstrap'
import * as actions from '../../actions/renderingQueueActions'
import {downloadAllAddToQueue, requestGetWhiteLabelledPdf, uploadLogoAndWhiteLabelPdf, clearWhiteLabelPdfFailCount} from '../../actions/whiteLabelPdfActions'
import '../../styles/components/RenderingQueue.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons'
import { openUploadLogoFromPreview, requestWhiteLabelDoc } from '../../actions/previewActions'
import { openPreviewTab } from '../../actions/tabActions'

class RenderingQueueItem extends React.Component {
	constructor(props) {
		super(props);
		this.handleRemoveSingle = this.handleRemoveSingle.bind(this);
		this.handleDownloadSingle = this.handleDownloadSingle.bind(this);
		this.handlePdfQueueClick = this.handlePdfQueueClick.bind(this);

		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
		}

		if (!Element.prototype.closest) {
			Element.prototype.closest = function(s) {
				var el = this;

				do {
					if (el.matches(s)) return el;
					el = el.parentElement || el.parentNode;
				} while (el !== null && el.nodeType === 1);
				return null;
			};
		}
	}

	getCurrentDocId(e) {
		return e.target.closest("[data-docid]").getAttribute("data-docid");
	}

	handleRemoveSingle(e) {
		e.stopPropagation();
		const currentDocId = this.getCurrentDocId(e);
		if (currentDocId !== "" && currentDocId !== undefined) {
			this.props.requestDeleteSingleRenderingQueue(currentDocId);
		}
	}

	handleDownloadSingle(e) {
		e.stopPropagation();
		this.props.clearWhiteLabelPdfFailCount();
		const currentDocId = this.getCurrentDocId(e);
		if (this.props.userLogo && this.props.userLogo != '') {
			if (currentDocId !== "" && currentDocId !== undefined) {
				let docIds = {
					documents: [currentDocId]
				};
				this.props.downloadAllAddToQueue(docIds);

				this.props.requestGetWhiteLabelledPdf(currentDocId);
			}
		}
		else {
			this.props.uploadLogoAndWhiteLabelPdf(currentDocId);
			const inputParams = {
				openUploadLogoFromPreviewFlag: true
			}
			this.props.openUploadLogoModal(inputParams);
		}
	}

	handlePdfQueueClick(e) {
		e.stopPropagation();
		const previewTabKey = 2
		const currentDocId = this.getCurrentDocId(e);
		this.props.requestWhiteLabelDoc(currentDocId);
		const tabParams = {
			previewDocId: currentDocId,
			activeTab: previewTabKey,
			previewHeadline: e.target.closest("[data-doc-headline]").getAttribute("data-doc-headline"),
			previewSubject: e.target.closest("[data-doc-subject]").getAttribute("data-doc-subject")
		}
		this.props.openPreviewTab(tabParams);
	}

	render() {
			return (
				<div key={this.props.documentId} onClick={this.handlePdfQueueClick} data-docid={this.props.documentId}
					className={`renderingQueueItemMain ${this.props.documentId == this.props.previewDocId
					? 'activePdfQueueItem' : ''}`}
					data-doc-headline={this.props.headline} data-doc-subject={this.props.subject} >
					<Row className="renderingQueueItem" data-docid={this.props.documentId}>
						<Col md={3} sm={3} className="queue-image-container">
							<ImageThumbnail imageUrl={this.props.imageUrl} />
						</Col>
						<Col md={9} sm={9} className="queue-text-container">
							<HeadLineTitle title={this.props.headline} />
							<HeadLineSubject data-doc-subject={this.props.subject} subject={this.props.subject} />
						</Col>
					</Row>
					<div className="renderingQueueButtons">
						<a href="#" className="removeQueueButton" data-docid={this.props.documentId} onClick={this.handleDownloadSingle}>
							<FontAwesomeIcon icon={faDownload} className="removeQueueIcon"/>
							Download
						</a>
					</div>
					<div className="renderingQueueButtons remove-single">
						<a href="#" className="removeQueueButton" data-docid={this.props.documentId} onClick={this.handleRemoveSingle}>
							<FontAwesomeIcon icon={faTimes} className="removeQueueIcon"/>
							Remove
						</a>
					</div>
					<hr />
				</div>
			)
		}
}

function mapStateToProps(state) {
	return {
		userLogo: state.headerReducer.userLogo,
		previewDocId: state.tabsReducer.previewDocId
	}
}

function mapDispatchToProps(dispatch) {
	return {
		requestDeleteSingleRenderingQueue: (docId) => {
			return dispatch(actions.requestDeleteSingleRenderingQueue(docId))
		},
		requestGetWhiteLabelledPdf: (docId) => {
			return dispatch(requestGetWhiteLabelledPdf(docId))
		},
		uploadLogoAndWhiteLabelPdf: (docId) => {
			return dispatch(uploadLogoAndWhiteLabelPdf(docId))
		},
		openUploadLogoModal: (inputParams) => {
			return dispatch(openUploadLogoFromPreview(inputParams));
		},
		requestWhiteLabelDoc: (docId) => {
			return dispatch(requestWhiteLabelDoc(docId))
		},
		openPreviewTab: (inputParams) => {
			return dispatch(openPreviewTab(inputParams))
		},
		downloadAllAddToQueue: (docIds) => {
			return dispatch(downloadAllAddToQueue(docIds))
		},
        clearWhiteLabelPdfFailCount: () => {
			return dispatch(clearWhiteLabelPdfFailCount())
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderingQueueItem)
