/* eslint-disable */
import Types from "./types";

const requestGetReportFilters = (inputParams) => ({
	type: Types.REQUEST_GET_REPORT_FILTERS_API,
	endPoint: "/reportFilters",
	ajaxType: "GET",
	params:
		inputParams,
	onSuccess: responseGetFilters
});

const responseGetFilters = response => ({
	type: (response.data.success === true) ? Types.GET_REPORT_FILTERS : Types.GENERIC_FAILURE,
	response
});

export {
	requestGetReportFilters
};