import _get from 'lodash/get';
import { createReducer } from 'reduxsauce';

import Types from '../actions/types';

export const INITIAL_STATE = {
  blackrock: {},
  markit: {}
};

const closeSession = (state, action) => {
  return INITIAL_STATE;
};

const updateSession = (state, action) => {
  return {
    ...state,
    blackrock: _get(action, 'result.blackrock'),
    markit: _get(action, 'result.markit')
  };
};

const ACTION_HANDLERS = {
  [Types.AUTHENTICATION_CLOSE_SESSION]: closeSession,
  [Types.AUTHENTICATION_UPDATE_SESSION]: updateSession
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
