/* eslint-disable */
import Types from "./types";

const onGenericApiFailure = response => ({
	type: Types.GENERIC_FAILURE,
	data: response.error
});

const onGenericApiSuccess = response => ({
	type: (response.data.success === true) ? Types.GENERIC_SUCCESS : Types.GENERIC_FAILURE,
	data: response.data
});

const resetFetchingFlag = () => ({
	type: Types.RESET_FETCHING_FLAG,
});

const resetLoadingFlag = () => ({
	type: Types.RESET_LOADING_FLAG,
});

const setLoadingFlag = () => ({
	type: Types.SET_LOADING_FLAG,
});



export {
    onGenericApiFailure,
    onGenericApiSuccess,
	resetFetchingFlag,
	resetLoadingFlag,
	setLoadingFlag
};
