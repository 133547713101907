/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Nav, NavItem, OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  faQuestionCircle,
  faPlus,
  faExclamationTriangle,
  faAngleRight,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import '../../styles/components/common/Header.scss';
import * as actions from '../../actions/headerActions';
import HowToPopupModal from '../common/HowToPopupModal';
import SignOff from '../SignOff';
import UploadLogoPopupModal from '../common/UploadLogoPopupModal';
import { openUploadLogoFromPreview } from '../../actions/previewActions';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddLogoClick = this.handleAddLogoClick.bind(this);
    this.handleRemoveLogoClick = this.handleRemoveLogoClick.bind(this);
    this.handleHowToPopupOpen = this.handleHowToPopupOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  handleAddLogoClick() {
    document.getElementById('root').click();
    const inputParams = {
      openUploadLogoFromPreviewFlag: true,
      docId: this.props.previewDocId
    };
    this.props.openUploadLogoFromPreview(inputParams);
  }

  handleRemoveLogoClick() {
    const inputParams = {
      userLogo: ''
    };
    this.props.requestPutUserLogoPref(inputParams);
  }

  handleHowToPopupOpen() {
    const inputParams = {
      openHowToModalFlag: true
    };
    this.props.openHowToModal(inputParams);
  }

  handlePopoverClose() {
    document.getElementById('root').click();
  }

  passwordResetUrl = [
    `${window.MD.authentication.blackrock.passwordResetUrl}?`,
    new URLSearchParams({
      issuerUri: encodeURI(`${window.MD.authentication.blackrock.issuerUrl}`),
      clientId: encodeURI(`${window.MD.authentication.blackrock.clientId}`),
      redirectUri: encodeURI(`${window.MD.authentication.blackrock.redirectUrl}/signoff`),
      site: 'tools'
    }).toString()
  ].join('');

  render() {
    const popoverLogoManagement = (
      <Popover id={'header-popover'} className="logo-management-popover">
        <div className="logo-management-header">
          <h4 className="logo-management-title">My Logo</h4>
          <button
            type="button"
            className="close logo-management-close"
            onClick={this.handlePopoverClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        {this.props.userLogo !== '' ? (
          <div className="logo-management-icon-container">
            <img src={this.props.userLogo} className="logo-management-icon" alt="Advisor logo" />
          </div>
        ) : (
          <p className="logo-management-zeroStateTxt">
            <i>
              You have not yet uploaded a logo into the tool. Select “Upload New” below to get
              started.
            </i>
          </p>
        )}
        <div>
          {this.props.userLogo !== '' ? (
            <a className="pull-left addLogoButton" href="#" onClick={this.handleRemoveLogoClick}>
              <FontAwesomeIcon icon={faTimes} className="addLogoIcon" />
              Remove
            </a>
          ) : (
            ''
          )}
          <a className="pull-right addLogoButton" href="#" onClick={this.handleAddLogoClick}>
            <FontAwesomeIcon icon={faPlus} className="addLogoIcon" />
            Upload New
          </a>
        </div>
      </Popover>
    );

    return (
      <Navbar inverse className="main-header">
        <Navbar.Header>
          <Navbar.Brand>
            <a href="#">BRAND & SHARE CONTENT LIBRARY</a>
          </Navbar.Brand>
        </Navbar.Header>
        <Nav pullRight>
          <NavItem
            eventKey={1}
            href={this.passwordResetUrl}
          >
            Change Password
          </NavItem>
          <SignOff eventKey={1} />
          <NavItem
            eventKey={1}
            href="#"
            className="pdftool-help-button"
            onClick={this.handleHowToPopupOpen}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
            Get Started
          </NavItem>
          <NavItem eventKey={2} href="#" className="advisor-logo-container">
            {this.props.userLogo !== '' ? (
              <div className="advisor-image">
                <img src={this.props.userLogo} className="user-logo" alt="Advisor logo" />
              </div>
            ) : (
              <div className="advisor-logo">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
            )}
          </NavItem>
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={popoverLogoManagement}
          >
            <NavItem eventKey={3} href="#" className="advisor-logo-right">
              <FontAwesomeIcon icon={faAngleRight} />
            </NavItem>
          </OverlayTrigger>
        </Nav>
        <HowToPopupModal />
        <UploadLogoPopupModal />
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLogo: state.headerReducer.userLogo,
    previewDocId: state.tabsReducer.previewDocId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openUploadLogoFromPreview: (inputParams) => {
      return dispatch(openUploadLogoFromPreview(inputParams));
    },
    openHowToModal: (inputParams) => {
      return dispatch(actions.openHowToModal(inputParams));
    },
    requestPutUserLogoPref: (inputParams) => {
      return dispatch(actions.requestPutUserLogoPref(inputParams));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
