/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import WHITELABEL_PDF_STATE from "./initial-state/whiteLabelPdfState";

const getWhiteLabelledPdf = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		whiteLabelledPdf: action.response.data.whiteLabelledPdf,
		whiteLabelPdfPendingUserLogo: '',
		whiteLabelledPdfFileName: action.response.data.documentName
	});

const setDownloadDocId = (state, action) =>
	Object.assign({}, state, {
		whiteLabelledPdfID: action.docId
	});

const uploadLogoAndWhiteLabelPdf = (state, action) =>
	Object.assign({}, state, {
		whiteLabelPdfPendingUserLogo: action.docId
	});

const clearWhiteLabelPdf = (state, action) =>
	Object.assign({}, state, {
		whiteLabelledPdf: '',
		whiteLabelledPdfFileName: ''
	});

const downloadAllAddToQueue = (state, action) =>
	Object.assign({}, state, {
		downloadAllReportIds: action.docIds.documents,
		downloadAllReportModalOpen: true,
		downloadAllReportIdsTotalCount: action.docIds.documents.length
	});

const downloadAllRemoveDownloaded = (state, action) =>
	Object.assign({}, state, {
		downloadAllReportIds: state.downloadAllReportIds.filter(docId => docId !== action.docId)
	});

const downloadAllComplete = (state, action) =>
	Object.assign({}, state, {
		downloadAllReportIds: [],
		whiteLabelledPdf: '',
		downloadAllReportIdsTotalCount: 0,
		whiteLabelledPdfID: '',
		whiteLabelledPdfFileName: '',
		downloadAllReportModalOpen: false
	});

const uploadLogoAndDownloadAllCall = (state, action) =>
	Object.assign({}, state, {
		uploadLogoAndDownloadAll: true
	});

const whiteLabelledPdfFail = (state, action) =>
 Object.assign({}, state, {
    whiteLabelledPdfFailIds:[...state.whiteLabelledPdfFailIds,state.whiteLabelledPdfID],
    fetching: false,
	error: null,
	downloadAllReportIds: state.downloadAllReportIds.filter(docId => docId !== state.whiteLabelledPdfID)
});

const clearWhiteLabelledPdfFail = (state, action) =>
 Object.assign({}, state, {
    whiteLabelledPdfFailIds: []
});

const ACTION_HANDLERS = {
	[Types.WHITELABEL_PDF]: getWhiteLabelledPdf,
	[Types.UPLOAD_LOGO_AND_WHITELABEL_PDF]: uploadLogoAndWhiteLabelPdf,
	[Types.CLEAR_WHITELABELDOC] : clearWhiteLabelPdf,
	[Types.DOWNLOAD_ALL_WHITELABELLED_ADD_TO_QUEUE]: downloadAllAddToQueue,
	[Types.DOWNLOAD_ALL_WHITELABELLED_REMOVE_DOWNLOADED]: downloadAllRemoveDownloaded,
	[Types.DOWNLOAD_ALL_WHITELABELLED_COMPLETE]: downloadAllComplete,
	[Types.OPEN_UPLOAD_LOGO_MODAL_DOWNLOAD_ALL]: uploadLogoAndDownloadAllCall,
    [Types.WHITELABEL_PDF_API]: setDownloadDocId,
    [Types.WHITELABELLED_PDF_FAIL]: whiteLabelledPdfFail,
    [Types.CLEAR_WHITELABELLED_PDF_FAIL]:clearWhiteLabelledPdfFail
};

export default createReducer(WHITELABEL_PDF_STATE, ACTION_HANDLERS);
