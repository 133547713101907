import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/components/common/Date.scss'
export const DocumentDate = (props) => {
  return (
    <div className='document-date'>
      { props.documentDate }
    </div>
  )
}

DocumentDate.propTypes = {
  documentDate: PropTypes.string
}
