// a library to wrap and simplify api calls
import apisauce from 'apisauce';

const markitApiService = {
  create: (baseURL, accessToken) => {
    let headers = {
      Accept: 'application/json'
    };
    if (accessToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`
      };
    }

    const api = apisauce.create({
      baseURL,
      headers,
      timeout: 35000 // 35 second timeout because of the report downloads etc.
    });

    const deleteData = ({ endPoint, params }) => api.delete(endPoint, params);
    const getData = ({ endPoint, params }) => api.get(endPoint, params);
    const postData = ({ endPoint, params }) => api.post(endPoint, params);
    const putData = ({ endPoint, params }) => api.put(endPoint, params);

    return {
      deleteData,
      getData,
      postData,
      putData
    };
  }
};

export default markitApiService;
