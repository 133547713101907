/* eslint-disable */
import Types from "./types";

const requestPostRenderingQueue = inputParams => ({
	type: Types.REQUEST_POST_RENDERING_QUEUE_API,
	endPoint: "/renderingQueue",
	params:
		inputParams,
	ajaxType: "POST",
	onSuccess: responsePostRenderingQueue
});

const responsePostRenderingQueue = response => ({
	type: (response.data.success === true) ? Types.POST_RENDERING_QUEUE : Types.GENERIC_FAILURE,
	response
});

const requestGetRenderingQueue = () => ({
	type: Types.REQUEST_GET_RENDERING_QUEUE_API,
	endPoint: "/renderingQueue",
	ajaxType: "GET",
	onSuccess: responseGetRenderingQueue
});

const responseGetRenderingQueue = response => ({
	type: (response.data.success === true) ? Types.GET_RENDERING_QUEUE : Types.GENERIC_FAILURE,
	response
});

const requestDeleteAllRenderingQueue = () => ({
	type: Types.REQUEST_DELETE_ALL_RENDERING_QUEUE_API,
	endPoint: "/renderingQueue",
	ajaxType: "DELETE",
	onSuccess: responseDeleteAllRenderingQueue
});

const responseDeleteAllRenderingQueue = response => ({
	type: (response.data.success === true) ? Types.DELETE_ALL_RENDERING_QUEUE : Types.GENERIC_FAILURE,
	response
});

const requestDeleteSingleRenderingQueue = docId => ({
	type: Types.REQUEST_DELETE_SINGLE_RENDERING_QUEUE_API,
	endPoint: "/renderingQueue/" + docId,
	ajaxType: "DELETE",
	onSuccess: responseDeleteSingleRenderingQueue
});

const responseDeleteSingleRenderingQueue = response => ({
	type: (response.data.success === true) ? Types.DELETE_SINGLE_RENDERING_QUEUE : Types.GENERIC_FAILURE,
	response
});

const browseAllAddToSelected = inputParams => ({
	type: Types.BROWSEALL_ADD_TO_SELECTED,
	inputParams
});

const browseAllRemoveFromSelected = inputParams => ({
	type: Types.BROWSEALL_REMOVE_FROM_SELECTED,
	inputParams
});

export {
	requestGetRenderingQueue,
	requestPostRenderingQueue,
	requestDeleteAllRenderingQueue,
	requestDeleteSingleRenderingQueue,
	browseAllAddToSelected,
	browseAllRemoveFromSelected
};