/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../actions/browseAllActions'
import * as previewActions from '../../actions/previewActions'
import PDF from 'react-pdf-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faQuestionCircle, faPlus, faExclamationTriangle, faPlay, faArrowUp, faCheckCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/Preview.scss'
import ErrorTemplate from '../common/errorTemplate'
import { Loader } from '../common/Loader'
import { Scrollbars } from 'react-custom-scrollbars';
import ReactPaginate from 'react-paginate';
import { resetLoadingFlag, setLoadingFlag } from '../../actions/commonActions'

const config = require('../../config/appSettings/appConfig')

class Preview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			pages: 0,
			showBorder: false,
			marginPagesDisplayed: config.preview.marginPagesDisplayed,
      		pageRangeDisplayed: config.preview.pageRangeDisplayed
		}
		this.onDocumentComplete = this.onDocumentComplete.bind(this)
		this.handleAddLogoClick = this.handleAddLogoClick.bind(this)
		this.handlePageClick = this.handlePageClick.bind(this)
		this.convertToUrl = this.convertToUrl.bind(this)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.previewDocId) {
			if (this.props.previewDocId != nextProps.previewDocId) {
				this.props.clearPreviewWhiteLabelPdf()
			}
			const inputParams = {
				recentlyViewedDoc: nextProps.previewDocId
			};
			this.props.requestPutRecentDocsPref(inputParams)
		}
	}

	convertToUrl(content) {
		let base64EncodedString = content.replace('data:application/pdf;base64,', '')
		var byteCharacters = atob(base64EncodedString)
		var byteNumbers = new Array(byteCharacters.length)
		for (var i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		var byteArray = new Uint8Array(byteNumbers)
		var blob = new Blob([byteArray], {
		type: 'application/pdf'
		})
		const dataUrl = URL.createObjectURL(blob)
		return dataUrl
	}

	onDocumentComplete(pages) {
		this.setState({ pages: pages, showBorder: true })
		this.props.resetLoadingFlag()
	}

	handlePageClick(data) {
		this.props.setLoadingFlag()
		var currentPage = data.selected + 1;
		this.setState({
			page: currentPage,
			showBorder: false
		})
	}

	handleAddLogoClick() {
		const inputParams = {
			openUploadLogoFromPreviewFlag: true,
			docId: this.props.previewDocId
		}
		this.props.openUploadLogoFromPreview(inputParams)
	}

	render() {
		return (
			<div className="preview-main">
				<ErrorTemplate tabKey={2}/>
				<div className={`preview-error-container ${!this.props.previewDocId || this.props.userLogo != "" ? 'hide-preview-error-module' : ''}`}>
					<div className="preview-error-icon-container pull-left">
						<FontAwesomeIcon icon={faExclamationCircle} className="preview-error-icon" />
					</div>
					<p>This is an unbranded preview because you have not yet uploaded your logo. Please
						<a href='#' className='preview-logo-add-button' onClick={this.handleAddLogoClick}>
							<span>upload your logo</span>
						</a>
						to download a customized version of this document.
					</p>
				</div>
				{this.props.previewDocId && this.props.whiteLabelledPdf ?
					<div className="preview-container">
						<div className="preview-header">
							<div className="preview-doc-heading">
								<p className="headline headline-title">{this.props.previewHeadline}</p>
							</div>
							<div className="preview-subheader">
							<p className="headline headline-subject">{this.props.previewSubject}</p>
							<div className="preview-pagination">
								<ReactPaginate
									previousLabel={'Previous'}
									nextLabel={'Next'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={this.state.pages}
									marginPagesDisplayed={this.state.marginPagesDisplayed}
									pageRangeDisplayed={this.state.pageRangeDisplayed}
									onPageChange={this.handlePageClick}
									containerClassName={'pagination'}
									subContainerClassName={'pages pagination'}
									activeClassName={'active'}
								/>
							</div>
							</div>
						</div>
						<Scrollbars autoHeight autoHeightMax={4000} className="custom-scrollbars">
						<PDF key={this.state.page} className={this.state.showBorder ? "pdf-border" : ""}
							file={this.convertToUrl(this.props.whiteLabelledPdf)}
							page={this.state.page}
							onDocumentComplete={this.onDocumentComplete} />
						</Scrollbars>
						</div> :
						!this.props.previewDocId ?
					<div className="preview-error-container no-preview">
						<div className="preview-error-icon-container pull-left">
							<FontAwesomeIcon icon={faExclamationCircle} className="preview-error-icon" />
						</div>
						<p>Please add a document to your queue to view what your logo will look like.</p>
					</div>
					: ""
				}
				{this.props.loading ? <Loader /> : ""}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		previewDocId: state.tabsReducer.previewDocId,
		userLogo: state.headerReducer.userLogo,
		previewHeadline: state.tabsReducer.previewHeadline,
		previewSubject: state.tabsReducer.previewSubject,
		whiteLabelledPdf: state.previewReducer.whiteLabelledPdf,
		loading: state.commonReducer.loading
	}
}

function mapDispatchToProps(dispatch) {
	return {
		requestPutRecentDocsPref: (inputParams) => {
			return dispatch(actions.requestPutRecentDocsPref(inputParams))
		},
		openUploadLogoFromPreview: (inputParams) => {
			return dispatch(previewActions.openUploadLogoFromPreview(inputParams))
		},
		clearPreviewWhiteLabelPdf: () => {
			return dispatch(previewActions.clearPreviewWhiteLabelPdf())
		},
		resetLoadingFlag: () => {
			return dispatch(resetLoadingFlag())
		},
		setLoadingFlag: () => {
			return dispatch(setLoadingFlag())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
