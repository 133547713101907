import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';

import './SignOffPage.scss';
import authenticationApi from '../../services/AuthenticationApi';
import spinner from '../../images/spinner.gif';
import { closeSession } from '../../actions/AuthenticationActions';

class SignOffPage extends React.Component {
  async componentDidMount() {
    // Clears Blackrock session
    await authenticationApi
      .create({
        baseURL: window.MD.authentication.blackrock.loginUrl,
        provider: authenticationApi.Providers.BLACKROCK
      })
      .deleteData({
        endPoint: `api/users/${_get(this.props.session, 'blackrock.id_token')}/sessions`
      })
      .catch((error) => {
        console.log(error);
      });

    // Clears local state
    this.props.closeSession();

    // Redirect to the sign-on page
    setTimeout(() => {
      window.location.replace(`${window.MD.authentication.blackrock.redirectUrl}/signon`);
    }, 0);
  }

  render() {
    return (
      <div className="signoff-root">
        <div className="spinner-container">
          <img alt={'Loading'} className={'spinner'} src={spinner} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.sessionData
});

const mapDispatchToProps = (dispatch) => ({
  closeSession: () => dispatch(closeSession())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignOffPage);
