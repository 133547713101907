/* eslint-disable */
import Types from "./types";

const requestGetWhiteLabelledPdf = docId => ({
	type: Types.WHITELABEL_PDF_API,
	endPoint: "/whiteLabelDoc/" + docId + '/true',
	ajaxType: "GET",
    onSuccess: responseGetWhiteLabelledPdf,
    onFailure:failureGetWhiteLabelledPdf,
    docId
});

const responseGetWhiteLabelledPdf =response => ({
	type: (response.data.success==true) ? Types.WHITELABEL_PDF :Types.WHITELABELLED_PDF_FAIL,
    response
});

const failureGetWhiteLabelledPdf = response => ({
    type: Types.WHITELABELLED_PDF_FAIL,
    response
});
const uploadLogoAndWhiteLabelPdf = docId => ({
	type: Types.UPLOAD_LOGO_AND_WHITELABEL_PDF,
	docId
});

const clearWhiteLabelPdf = () => ({
	type: Types.CLEAR_WHITELABELDOC
});

const downloadAllAddToQueue = (docIds) => ({
	type: Types.DOWNLOAD_ALL_WHITELABELLED_ADD_TO_QUEUE,
	docIds
});

const downloadAllRemoveDownloaded = (docId) => ({
	type: Types.DOWNLOAD_ALL_WHITELABELLED_REMOVE_DOWNLOADED,
	docId
});

const downloadAllComplete = () => ({
	type: Types.DOWNLOAD_ALL_WHITELABELLED_COMPLETE
});

const uploadLogoAndDownloadAllCall = () => ({
	type: Types.OPEN_UPLOAD_LOGO_MODAL_DOWNLOAD_ALL
});

 const clearWhiteLabelPdfFailCount =() =>({
    type: Types.CLEAR_WHITELABELLED_PDF_FAIL
 })
export {
	requestGetWhiteLabelledPdf,
	uploadLogoAndWhiteLabelPdf,
	clearWhiteLabelPdf,
	downloadAllAddToQueue,
	downloadAllRemoveDownloaded,
	downloadAllComplete,
	uploadLogoAndDownloadAllCall,
    clearWhiteLabelPdfFailCount
};