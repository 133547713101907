/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import COMMON_STATE from "./initial-state/commonState";

const genericApiFailure = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		loading: false,
		error: action.data
	});

const genericApiSuccess = (state, action) =>
	Object.assign({}, state, {
	});

const resetFetchingFlag = (state, action) =>
	Object.assign({}, state, {
		fetching: undefined
	});

const resetLoadingFlag = (state, action) =>
	Object.assign({}, state, {
		loading: false
	});

const setLoadingFlag = (state, action) =>
	Object.assign({}, state, {
		loading: true
	});

const ACTION_HANDLERS = {
	[Types.GENERIC_FAILURE]: genericApiFailure,
	[Types.GENERIC_SUCCESS]: genericApiSuccess,
	[Types.RESET_FETCHING_FLAG]: resetFetchingFlag,
	[Types.RESET_LOADING_FLAG]: resetLoadingFlag,
	[Types.REQUEST_GET_WHITELABEL_DOC_API]: setLoadingFlag,
	[Types.SET_LOADING_FLAG]: setLoadingFlag
};

export default createReducer(COMMON_STATE, ACTION_HANDLERS);
