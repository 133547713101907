/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import REPORTFILTER_STATE from "./initial-state/reportFilterState";

const getReportFilters = (state, action) =>
	Object.assign({}, state, {
		reportFilters: action.response.data.reportFilters,
		reportTypes: action.response.data.reportFilters.reportTypes,
		reportContents: action.response.data.reportFilters.reportContents
	});

const ACTION_HANDLERS = {
	[Types.GET_REPORT_FILTERS]: getReportFilters
};

export default createReducer(REPORTFILTER_STATE, ACTION_HANDLERS);
