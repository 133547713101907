/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _get from 'lodash/get';
import { NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';

import authenticationHelper from '../../utils/AuthenticationHelper';

class SignOff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  static getDerivedStateFromProps(props) {
    return {
      isAuthenticated: authenticationHelper.isAuthenticated(props.session)
    };
  }

  async handleLogout() {
    /* Spec 1.3
    // Revokes the Blackrock access token
    await authenticationApi
      .create({
        baseURL: window.MD.authentication.blackrock.issuerUrl,
        provider: authenticationApi.Providers.BLACKROCK
      })
      .postData({
        endPoint: '/v1/revoke',
        parameters: new URLSearchParams({
          client_id: window.MD.authentication.blackrock.clientId,
          token: this.props.session.blackrock.accessToken,
          token_type_hint: 'access_token'
        })
      })
      .catch((error) => {
        console.log(error);
      });

    // Deletes the Blackrock session
    await authenticationApi
      .create({
        baseURL: new URL(window.MD.authentication.blackrock.issuerUrl).origin,
        provider: authenticationApi.Providers.BLACKROCK
      })
      .deleteData({ endPoint: '/api/v1/sessions/me' })
      .catch((error) => {
        console.log(error);
      });
    */

    // Clear authenication state.
    delete sessionStorage.hostname;
    delete sessionStorage.pkce_code_verifier;
    delete sessionStorage.pkce_state;

    // Spec 2.0
    // Redirects to logout.
    window.location.replace(
      `${window.MD.authentication.blackrock.issuerUrl}/v1/logout?${new URLSearchParams({
        id_token_hint: encodeURI(_get(this.props.session, 'blackrock.idToken')),
        post_logout_redirect_uri: encodeURI(`${window.MD.authentication.blackrock.redirectUrl}/signoff`),
        state: ''
      }).toString()}`
    );
  }

  render() {
    return (
      <>
        {this.state.isAuthenticated && (
          <NavItem
            className={this.props.className}
            eventKey={this.props.eventKey}
            href="#"
            onClick={this.handleLogout}
          >
            Log Out
          </NavItem>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.sessionData
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SignOff);
