/* eslint-disable */
import React from 'react';
import { Image } from 'react-bootstrap'
import sample_thumbnail from '../../images/sample_thumbnail.png'
import sample_thumbnail1 from '../../images/sample_thumbnail1.png'


export default class ImageThumbnail extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render() {
        const imageUrl = this.props.imageUrl ? this.props.imageUrl : sample_thumbnail1
        return (
            <Image className='img-thumbnail' src={imageUrl} thumbnail responsive />
        )
    }
}
