/* eslint-disable */
import Types from "./types";

const requestGetReports = (inputParams) => ({
	type: Types.REQUEST_GET_REPORTS_API,
	endPoint: "/reports",
	ajaxType: "GET",
	params:
		inputParams,
	onSuccess: responseGetReports
});

const requestGetRecentReports = () => ({
	type: Types.REQUEST_GET_RECENT_REPORTS_API,
	endPoint: "reports/recentlyViewedReports",
	ajaxType: "GET",
	onSuccess: responseGetRecentReports
});

const responseGetReports = response => ({
	type: (response.data.success === true) ? Types.GET_REPORTS : Types.GENERIC_FAILURE,
	response
});

const responseGetRecentReports = response => ({
	type: (response.data.success === true) ? Types.GET_RECENT_REPORTS : Types.GENERIC_FAILURE,
	response
});

const requestPutRecentDocsPref = inputParams => ({
	type: Types.REQUEST_PUT_RECENT_REPORTS_PREF_API,
	endPoint: "/user/recentlyViewedPref",
	params:
		inputParams,
	ajaxType: "PUT"
});

export {
	requestGetReports,
	requestGetRecentReports,
	requestPutRecentDocsPref
};