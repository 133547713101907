/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import ImageThumbnail from '../common/ImageThumbnail'
import { HeadLineTitle, HeadLineSubject } from '../common/Headline'
import { DocumentDate } from '../common/Date'
import { Row, Col, Button } from 'react-bootstrap'
import { requestPostRenderingQueue } from '../../actions/renderingQueueActions'
import { browseAllAddToSelected, browseAllRemoveFromSelected } from '../../actions/renderingQueueActions'
import * as tabActions from '../../actions/tabActions'
import '../../styles/components/BrowseAllPdfs/ReportItem.scss'
import '../../styles/components/common/Buttons.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import * as previewActions from '../../actions/previewActions'

class ReportItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReports: []
    }
    this.addToQueueClickHandler = this.addToQueueClickHandler.bind(this);
    this.previewClickHandler = this.previewClickHandler.bind(this);
	this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}

	if (!Element.prototype.closest) {
		Element.prototype.closest = function(s) {
			var el = this;

			do {
				if (el.matches(s)) return el;
				el = el.parentElement || el.parentNode;
			} while (el !== null && el.nodeType === 1);
			return null;
		};
	}
  }

  getCurrentDocId(e) {
    return e.target.closest("[data-docid]").getAttribute("data-docid");
  }

  addToQueueClickHandler(e) {
    const currentDocId = this.getCurrentDocId(e);
    if (currentDocId !== "" && currentDocId !== undefined) {
      const inputParams = {
        documentId: currentDocId
      }
      this.props.requestPostRenderingQueue(inputParams);
    }
  }

  previewClickHandler(e) {
    const previewTabKey = 2;
    const currentDocId = this.getCurrentDocId(e);
    const tabParams = {
      previewDocId: currentDocId,
      activeTab: previewTabKey,
      previewHeadline: e.target.closest("[data-doc-headline]").getAttribute("data-doc-headline"),
      previewSubject: e.target.closest("[data-doc-subject]").getAttribute("data-doc-subject")
    }
    this.props.openPreviewTab(tabParams);
    this.props.requestWhiteLabelDoc(currentDocId);
  }

  handleCheckboxChange(e) {
    const currentDocId = this.getCurrentDocId(e);
    const inputParams = {
      docId: currentDocId
    }
    if (e.target.checked) {
      if (this.props.selectedReports && this.props.selectedReports.indexOf(currentDocId) == -1) {
        this.props.browseAllAddToSelected(inputParams)
      }
    }
    else {
      if (this.props.selectedReports && this.props.selectedReports.indexOf(currentDocId) != -1) {
        this.props.browseAllRemoveFromSelected(inputParams)
      }
    }
  }

  render() {
    return (
      <Row className="reportitem-main" data-docid={this.props.documentId} data-doc-headline={this.props.headline} data-doc-subject={this.props.subject}>
        <Col md={1} sm={1} className="report-checkbox-div">
          <label className="report-checkbox-container">
            <input key={this.props.documentId} type="checkbox" className="report-checkbox" onChange={this.handleCheckboxChange}
            checked={this.props.selectedReports.indexOf(this.props.documentId) != -1} />
            <span className="report-checkbox-checkmark"></span>
          </label>
        </Col>
        <Col md={2} sm={2} className="report-image-container">
          <ImageThumbnail imageUrl={this.props.imageUrl} />
        </Col>
        <Col md={7} sm={7}>
          <HeadLineTitle title={this.props.headline} />
          <HeadLineSubject subject={this.props.subject} />
          <div>
            <Button className="button button-black add-to-selection-button"
              onClick={this.addToQueueClickHandler}>
              <span>Add to My Selection</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
            <Button className="button button-white preview-button"
              onClick={this.previewClickHandler}>
              <span>Preview</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          </div>
        </Col>
        <Col md={2} sm={2} className="browseall-header-datePublish--PaddingFix">
          <DocumentDate documentDate={this.props.dateAsOf} />
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedReports: state.renderingQueueReducer.selectedReports,
    renderingQueueReports: state.renderingQueueReducer.renderingQueueReports
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openPreviewTab: (inputParams) => {
      return dispatch(tabActions.openPreviewTab(inputParams))
    },
    requestPostRenderingQueue: (inputParams) => {
      return dispatch(requestPostRenderingQueue(inputParams))
    },
    browseAllAddToSelected: (inputParams) => {
      return dispatch(browseAllAddToSelected(inputParams))
    },
    browseAllRemoveFromSelected: (inputParams) => {
      return dispatch(browseAllRemoveFromSelected(inputParams))
    },
    requestWhiteLabelDoc: (docId) => {
			return dispatch(previewActions.requestWhiteLabelDoc(docId))
		}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportItem)
