/* eslint-disable */
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker-3';
import React from 'react';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, DropdownButton, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { faPlus, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import '../../styles/components/BrowseAllPdfs/BrowseAllPdfs.scss';
import '../../styles/components/common/Pagination.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as actions from '../../actions/browseAllActions';
import ErrorTemplate from '../common/errorTemplate';
import ReportItem from './ReportItem';
import { ModuleHeader } from '../common/ModuleHeader';
import { requestGetReportFilters } from '../../actions/reportFilterActions';
import { requestPostRenderingQueue } from '../../actions/renderingQueueActions';

const config = require('../../config/appSettings/appConfig');

class BrowseAllPdfs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalReports: 0,
      pageSize: config.browseAll.pageSize,
      pages: 0,
      marginPagesDisplayed: config.browseAll.marginPagesDisplayed,
      pageRangeDisplayed: config.browseAll.pageRangeDisplayed,
      selectedReportType: null,
      selectedContentType: null,
      DescSort: true,
      resetPage: false,
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      startDateFilter: '',
      endDateFilter: '',
      isAllDateFilterApplied: true
    };
    this.handleAddSelectedToQueue = this.handleAddSelectedToQueue.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.changeReportType = this.changeReportType.bind(this);
    this.changeContentType = this.changeContentType.bind(this);
    this.changeSorting = this.changeSorting.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.updateReportFilters = this.updateReportFilters.bind(this);
  }

  handleApply(e, datepicker) {
    if (
      moment(datepicker.startDate).format('YYYY-MM-DD') ===
        moment().subtract(30, 'days').format('YYYY-MM-DD') &&
      moment(datepicker.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      this.setState({
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        startDateFilter: '',
        endDateFilter: '',
        isAllDateFilterApplied: true
      });
    } else {
      this.setState({
        startDate: moment(datepicker.startDate),
        endDate: moment(datepicker.endDate),
        startDateFilter: moment(datepicker.startDate).format(),
        endDateFilter: moment(datepicker.endDate).format(),
        isAllDateFilterApplied: false
      });
    }
    this.updateReports();
  }

  componentDidMount() {
    const inputParams = {
      firstRow: 0,
      rowCount: this.state.pageSize,
      sortOrder: this.state.DescSort ? 'Desc' : 'Asc'
    };
    this.props.requestGetReports(inputParams);
    this.props.requestGetRecentReports();
    this.props.requestGetReportFilters();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.reportCount) {
      this.setState({
        totalReports: nextProps.reportCount,
        pages: Math.ceil(nextProps.reportCount / this.state.pageSize)
      });
    }
  }

  handlePageClick(data) {
    let currentPage = data.selected;
    const inputParams = {
      firstRow: currentPage * this.state.pageSize,
      rowCount: this.state.pageSize,
      sortOrder: this.state.DescSort ? 'Desc' : 'Asc'
    };
    if (this.state.selectedReportType) {
      inputParams['filter_ReportType'] = this.state.selectedReportType;
    }
    if (this.state.selectedContentType) {
      inputParams['filter_ReportContent'] = this.state.selectedContentType;
    }
    if (this.state.startDateFilter !== '') {
      inputParams['filter_DocumentDate_GreaterThan'] = this.state.startDateFilter;
    }
    if (this.state.endDateFilter !== '') {
      inputParams['filter_DocumentDate_LessThan'] = this.state.endDateFilter;
    }
    this.props.requestGetReports(inputParams);
  }

  handleAddSelectedToQueue() {
    const docIds = this.props.selectedReports.join('|');
    const inputParams = {
      documentId: docIds
    };
    this.props.requestPostRenderingQueue(inputParams);
  }

  updateReports() {
    const inputParams = {
      firstRow: 0,
      rowCount: this.state.pageSize,
      sortOrder: this.state.DescSort ? 'Desc' : 'Asc'
    };
    if (this.state.selectedReportType) {
      inputParams['filter_ReportType'] = this.state.selectedReportType;
    }
    if (this.state.selectedContentType) {
      inputParams['filter_ReportContent'] = this.state.selectedContentType;
    }
    if (this.state.startDateFilter !== '') {
      inputParams['filter_DocumentDate_GreaterThan'] = this.state.startDateFilter;
    }
    if (this.state.endDateFilter !== '') {
      inputParams['filter_DocumentDate_LessThan'] = this.state.endDateFilter;
    }
    this.props.requestGetReports(inputParams);
    this.setState({
      resetPage: !this.state.resetPage
    });
  }

  changeReportType(e) {
    const currentReportType = e.target.getAttribute('data-report-type');
    if (this.state.selectedReportType === currentReportType) {
      this.state.selectedReportType = null;
    } else {
      this.state.selectedReportType = currentReportType;
    }
    this.updateReports();
    this.props.requestGetReportFilters();
    document.getElementById('root').click();
  }

  changeContentType(e) {
    const currentContentType = e.target.getAttribute('data-report-content');
    if (this.state.selectedContentType === currentContentType) {
      this.state.selectedContentType = null;
    } else {
      this.state.selectedContentType = currentContentType;
    }
    this.updateReports();
    this.props.requestGetReportFilters();
    document.getElementById('root').click();
  }

  changeSorting() {
    this.state.DescSort = !this.state.DescSort;
    this.updateReports();
  }

  updateReportFilters() {
    this.props.requestGetReportFilters();
  }

  render() {
    if (this.props && this.props.reportCount > -1) {
      return (
        <div className="browseall-main">
          <div className="browseall-filters">
            <OverlayTrigger
              key="tooltip-bottom-reportType"
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom-reportType`}>
                  <strong>
                    {this.state.selectedReportType ? this.state.selectedReportType : 'Report Type'}
                  </strong>
                </Tooltip>
              }
            >
              <DropdownButton
                id="dropdown-item-button-ReportType"
                data-toggle="dropdown"
                text={this.state.selectedReportType}
                className={
                  this.state.selectedReportType
                    ? 'browseall-filter-dropdown-active dropdown-toggle'
                    : 'browseall-filter-dropdown '
                }
                title={
                  this.state.selectedReportType ? this.state.selectedReportType : 'Report Type'
                }
              >
                {this.props.reportTypes.map((reportType) => (
                  <li key={reportType.reportType} onClick={this.changeReportType}>
                    <a
                      key={reportType.reportType}
                      data-report-type={reportType.reportType}
                      className={
                        this.state.selectedReportType === reportType.reportType
                          ? 'browseall-filter-dropdown-selected'
                          : 'browseall-filter-dropdown_list'
                      }
                    >
                      {reportType.reportType}
                    </a>
                  </li>
                ))}
              </DropdownButton>
            </OverlayTrigger>
            <DatetimeRangePicker
              showDropdowns
              ranges={{
                'All Reports': [moment().subtract(30, 'days'), moment()],
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                  moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month')
                ]
              }}
              startDate={moment(this.state.startDate)}
              endDate={moment(this.state.endDate)}
              onApply={this.handleApply}
              className="btn-group dropdown publish-date-range"
            >
              <Button
                className={`${
                  this.state.isAllDateFilterApplied
                    ? 'browseall-filter-dropdown publish-date-button'
                    : 'browseall-filter-dropdown-active publish-date-button-active dropdown-toggle'
                }`}
              >
                <span>
                  {this.state.isAllDateFilterApplied
                    ? 'Publish Date Range'
                    : moment(this.state.startDate).format('ll') +
                      ' to ' +
                      moment(this.state.endDate).format('ll')}
                </span>
              </Button>
            </DatetimeRangePicker>
            <OverlayTrigger
              key="tooltip-bottom-contentType"
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom-contentType`}>
                  <strong>
                    {this.state.selectedContentType
                      ? this.state.selectedContentType
                      : 'Report Content'}
                  </strong>
                </Tooltip>
              }
            >
              <DropdownButton
                id="dropdown-item-button-ContentType"
                data-toggle="dropdown"
                className={
                  this.state.selectedContentType
                    ? 'browseall-filter-dropdown-active'
                    : 'browseall-filter-dropdown'
                }
                title={
                  this.state.selectedContentType ? this.state.selectedContentType : 'Report Content'
                }
              >
                {this.props.reportContents.map((reportContent) => (
                  <li key={reportContent.reportContent} onClick={this.changeContentType}>
                    <a
                      key={reportContent.reportContent}
                      data-report-content={reportContent.reportContent}
                      className={
                        this.state.selectedContentType === reportContent.reportContent
                          ? 'browseall-filter-dropdown-selected'
                          : 'browseall-filter-dropdown_list'
                      }
                    >
                      {reportContent.reportContent}
                    </a>
                  </li>
                ))}
              </DropdownButton>
            </OverlayTrigger>
          </div>
          <ErrorTemplate tabKey={1} />
          <div className="browseall-addtoqueuebar">
            <span className="browseall-header-text">
              Showing{' '}
              <span className="browseall-header-Font">
                {this.props.reportCount ? this.props.reportCount : 0}{' '}
              </span>
              of {this.props.initialReportCount} Customizable Documents
            </span>
            <a
              className={`pull-right addQueueButton ${
                this.props.selectedReports.length > 0 ? '' : 'disabled'
              }`}
              href="#"
              onClick={this.handleAddSelectedToQueue}
            >
              <FontAwesomeIcon icon={faPlus} className="addQueueIcon" />
              Add to My Selection
            </a>
          </div>
          <Row className="browseall-reports-heading">
            <Col md={1} sm={1}></Col>
            <Col md={2} sm={2}></Col>
            <Col md={7} sm={7}>
              Document Name and Category
            </Col>
            <Col
              md={2}
              sm={2}
              className="browseall-header-datePublish--PaddingFix"
              onClick={this.changeSorting}
            >
              Date Published
              {this.state.DescSort ? (
                <FontAwesomeIcon icon={faSortDown} className="browseall-reports-heading-sortIcon" />
              ) : (
                <FontAwesomeIcon icon={faSortUp} className="browseall-reports-heading-sortIcon" />
              )}
            </Col>
          </Row>
          <Scrollbars autoHeight autoHeightMax={4000} className="custom-scrollbars">
            {this.props.recentReports && this.props.recentReports.length > 0 ? (
              <div className="browseall-recentreports" show={this.state.hasRecentReports}>
                <ModuleHeader title="Recently Viewed" class="header-recently-viewed" />
                {this.props.recentReports
                  ? this.props.recentReports.map((report, index) => (
                      <ReportItem key={index} {...report} />
                    ))
                  : ''}
              </div>
            ) : null}
            <ModuleHeader title="All Reports" class="header-all-reports" />
            {this.props.reportCount == 0 ? (
              'No records found'
            ) : (
              <div>
                <div className="browseall-allreports">
                  {this.props.reports.map((report, index) => (
                    <ReportItem key={index} {...report} />
                  ))}
                </div>
                <div className="browseall-pagination">
                  <ReactPaginate
                    key={this.state.resetPage}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pages}
                    marginPagesDisplayed={this.state.marginPagesDisplayed}
                    pageRangeDisplayed={config.browseAll.pageRangeDisplayed}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            )}
          </Scrollbars>
        </div>
      );
    } else {
      return (
        <div>
          <ErrorTemplate tabKey={1} />
          Loading...
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    reports: state.browseAllReducer.reports,
    recentReports: state.browseAllReducer.recentReports,
    selectedReports: state.renderingQueueReducer.selectedReports,
    reportCount: state.browseAllReducer.reportCount,
    initialReportCount: state.browseAllReducer.initialReportCount,
    reportTypes: state.reportFilterReducer.reportFilters.reportTypes,
    reportContents: state.reportFilterReducer.reportFilters.reportContents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestGetReports: (inputParams) => {
      return dispatch(actions.requestGetReports(inputParams));
    },
    requestGetRecentReports: () => {
      return dispatch(actions.requestGetRecentReports());
    },
    requestPostRenderingQueue: (inputParams) => {
      return dispatch(requestPostRenderingQueue(inputParams));
    },
    requestGetReportFilters: (inputParams) => {
      return dispatch(requestGetReportFilters(inputParams));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseAllPdfs);
