import apiSauce from 'apisauce';

const Providers = {
  BLACKROCK: 'blackrock',
  MARKIT: 'markit',
};
Object.freeze(Providers);

const create = ({ baseURL, provider }) => {
  const timeout = 15000; // milliseconds
  let api;

  switch (provider) {
    case Providers.BLACKROCK:
    default:
      api = apiSauce.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        timeout,
        withCredentials: true,
      });
      break;

    case Providers.MARKIT:
      api = apiSauce.create({
        baseURL,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        timeout,
      });
      break;
  }

  const deleteData = ({ endPoint, parameters }) => api.delete(endPoint, parameters);
  const getData = ({ endPoint, parameters }) => api.get(endPoint, parameters);
  const postData = ({ endPoint, parameters }) => api.post(endPoint, parameters);

  return {
    api,
    deleteData,
    getData,
    postData,
  };
};

const authenticationApi = {
  create,
  Providers,
};
export default authenticationApi;
