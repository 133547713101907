/* eslint-disable */
const retrieve = (localAccessToken) => {
  // token from meta > token from local storage > local access token
  const accessTokenMeta = document.querySelector("meta[name='access_token']")
  const accessTokenFromMeta = accessTokenMeta ? accessTokenMeta.getAttribute('value') : null
  const accessTokenFromLocalStorage = localStorage.getItem('access_token')
  const accessToken = accessTokenFromMeta || (accessTokenFromLocalStorage || localAccessToken || '')
  if (localStorage.getItem('loggedOut') === "true" && localStorage.getItem('logout-token') === accessTokenFromMeta) {
    return ''
  }
  else {
    return accessToken
  }
}

const accessTokenService = {
  retrieve
};
export default accessTokenService;
