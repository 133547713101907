/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import BROWSEALL_STATE from "./initial-state/browseAllState";

const getReports = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		reports: action.response.data.reportsResponse.reports,
        reportCount: action.response.data.reportsResponse.count,
        initialReportCount: (state.initialReportCount<0) ? action.response.data.reportsResponse.count : state.initialReportCount
	});

const getRecentReports = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		recentReports: action.response.data.reportsResponse.reports,
		recentReportCount: action.response.data.count
	});

const ACTION_HANDLERS = {
	[Types.GET_REPORTS]: getReports,
	[Types.GET_RECENT_REPORTS]: getRecentReports
};

export default createReducer(BROWSEALL_STATE, ACTION_HANDLERS);
