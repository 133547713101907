/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../actions/headerActions'
import { requestGetWhiteLabelledPdf, clearWhiteLabelPdf, downloadAllAddToQueue } from '../../actions/whiteLabelPdfActions'
import { Modal, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import '../../styles/components/common/Header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCheckCircle, faExclamationCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import Slider from '@material-ui/core/Slider';
import { openUploadLogoFromPreview, requestWhiteLabelDoc } from '../../actions/previewActions'

class UploadLogoPopupModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadLogoPopupOpen: false,
            fileUploaded: '',
            fileUploadStatus: '',
            fileName: '',
            imgWidth: 0,
            imgHeight: 0,
            editorWidth: 0,
            editorHeight: 0,
            editorScale: 1,
            marginTop: 30,
            dropZoneClickDisabled: false,
        }
        this.handleAddLogoPopupClose = this.handleAddLogoPopupClose.bind(this);
        this.handleAddLogoPopupSave = this.handleAddLogoPopupSave.bind(this);
        this.handleNewFileUpload = this.handleNewFileUpload.bind(this);
        this.handleDummyImageLoad = this.handleDummyImageLoad.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleImageScaleMinus = this.handleImageScaleMinus.bind(this);
        this.handleImageScalePlus = this.handleImageScalePlus.bind(this);
        this.setEditorRef = this.setEditorRef.bind(this);
        this.resetUploadModal = this.resetUploadModal.bind(this);
    }

    componentDidMount() {
        this.props.requestGetUserLogoPref();
    }

    resetUploadModal() {
        this.setState({ fileUploadStatus: '', fileName: '', fileUploaded: '', dropZoneClickDisabled: false, editorScale: 1 });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openUploadLogoFromPreviewFlag) {
            this.setState({ uploadLogoPopupOpen: true });
            const inputParams = {
                openUploadLogoFromPreviewFlag: false,
                docId: this.props.previewDocId
            }
            this.props.openUploadLogoFromPreview(inputParams);
        }

        if (this.props.userLogo !== nextProps.userLogo && nextProps.userLogo != "") {
            if (this.props.whiteLabelPdfPendingUserLogo && this.props.whiteLabelPdfPendingUserLogo != '') {
                let docIds = {
                    documents: [this.props.whiteLabelPdfPendingUserLogo]
                };
                this.props.downloadAllAddToQueue(docIds);

                let currentDocId = docIds.documents[0];

                if (currentDocId !== "" && currentDocId !== undefined) {
                    this.props.requestGetWhiteLabelledPdf(currentDocId);
                }
            }
            if (this.props.previewPdfPendingUserLogo && this.props.previewPdfPendingUserLogo != '') {
                this.props.requestWhiteLabelDoc(this.props.previewPdfPendingUserLogo);
            }
            if (this.props.uploadLogoAndDownloadAll) {
                let docIds = {
                    documents: this.props.renderingQueueReports.map(report => report.documentId)
                };
                this.props.downloadAllAddToQueue(docIds);

                let currentDocId = docIds.documents[0];

                if (currentDocId !== "" && currentDocId !== undefined) {
                    this.props.requestGetWhiteLabelledPdf(currentDocId);
                }
            }
        }

        if (nextProps.refreshUserLogo && nextProps.refreshUserLogo === true) {
            nextProps.requestGetUserLogoPref();
        }

        if (nextProps && nextProps.whiteLabelledPdf && nextProps.whiteLabelledPdf != "" && !this.props.downloadAllReportModalOpen) {
            let linkSource = nextProps.whiteLabelledPdf;
            let fileName = nextProps.whiteLabelledPdfFileName + '.pdf';

            // IE workaround to download pdf file
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var byteCharacters = atob(linkSource.replace("data:application/pdf;base64,", ""));
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'application/pdf' });
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            }
            else { // sudo element download approach for downlaoding pdf
                let downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }
            this.props.clearWhiteLabelPdf();
        }
    }

    handleAddLogoPopupClose() {
        this.resetUploadModal();
        this.setState({ uploadLogoPopupOpen: false });
    }

    handleNewFileUpload() {
        this.resetUploadModal();
    }

    onImageDrop(files) {
        if (files.length > 0 && files[0].size <= 2048000) {
            this.setState({ fileName: files[0].name });
            let self = this;
            this.setState({ fileUploadStatus: 'Uploading' });
            setTimeout(
                function () {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const fileAsBinaryString = reader.result;
                        self.setState({
                            fileUploaded: fileAsBinaryString
                        });
                    };
                    reader.onabort = () => { this.setState({ fileUploadStatus: 'UploadError' }) };
                    reader.onerror = () => { this.setState({ fileUploadStatus: 'UploadError' }) };

                    reader.readAsDataURL(files[0]);
                }
                    .bind(this),
                1500
            );
        }
        else {
            this.setState({ fileUploadStatus: 'UploadError' });
        }
    }

    handleAddLogoPopupSave() {
        if (this.editor) {
            const inputParams = {
                userLogo: this.editor.getImageScaledToCanvas().toDataURL()
            };
            this.props.requestPutUserLogoPref(inputParams);
            this.setState({ uploadLogoPopupOpen: false })
            this.resetUploadModal();
        }
    }

    handleDummyImageLoad(e) {
        if (e.currentTarget && e.currentTarget.width < 200 && e.currentTarget.height < 200) {
            this.setState({ fileUploadStatus: 'UploadError' })
        }
        else if (e.currentTarget) {
            let height = e.currentTarget.height, width = e.currentTarget.width;
            let edtrHeight = 0, edtrWidth = 0, scaleMultiplier = 1;
            let mrgTop = 0;
            if (width >= height && width >= 200) {
                edtrWidth = 200;
                scaleMultiplier = 200 / width;
                edtrHeight = scaleMultiplier * height;
                mrgTop = ((200 - edtrHeight - 2) / 2) + 1;
            }
            else if (height > width && height >= 200) {
                edtrHeight = 200;
                scaleMultiplier = 200 / height;
                edtrWidth = scaleMultiplier * width;
                mrgTop = 0;
            }

            this.setState({ imgWidth: width, imgHeight: height, fileUploadStatus: 'UploadComplete', editorWidth: edtrWidth, editorHeight: edtrHeight, marginTop: mrgTop, dropZoneClickDisabled: true })
        }
    }

    handleSliderChange(event, value) {
        this.setState({ editorScale: value });
    }

    handleImageScaleMinus() {
        let minusVal = this.state.editorScale;
        if (minusVal > 1) {
            minusVal = minusVal - 0.1;
            this.setState({ editorScale: minusVal });
        }
    }

    handleImageScalePlus() {
        let plusVal = this.state.editorScale;
        if (plusVal < 2) {
            plusVal = plusVal + 0.1;
            this.setState({ editorScale: plusVal });
        }
    }

    setEditorRef(editor) {
        this.editor = editor;
    }

    render() {
        const baseStyle = {
            width: 204,
            height: 204,
            borderWidth: 2,
            borderColor: '#a5a5a5',
            borderStyle: 'dashed',
            backgroundColor: "#EFEFEF"
        };
        const activeStyle = {
            borderColor: '#008B5C',
            borderStyle: 'dashed',
            backgroundColor: "#EFEFEF"
        };
        const rejectStyle = {
            borderColor: '#C00B28',
            borderStyle: 'dashed',
            backgroundColor: "#EFEFEF"
        };
        const imagePreviewStyle = {
            marginTop: this.state.marginTop
        };
        const imageScaleStyle = {
            width: 204,
            borderWidth: 2,
            borderColor: '#a5a5a5',
            borderStyle: 'solid'
        };

        return (
            <Modal show={this.state.uploadLogoPopupOpen} onHide={this.handleAddLogoPopupClose} keyboard={false} className="blk-modal upload-logo-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Upload your logo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="modal-subheader upload-logo-subheader">
                        Please upload your logo to create and download customized documents
						</p>
                    <Row>
                        <Col md={5} sm={4} className="upload-logo-left">
                            <div className="upload-dropzone-container">
                                <Dropzone accept="image/jpeg, image/png" onDrop={this.onImageDrop.bind(this)} multiple={false} noClick={this.state.dropZoneClickDisabled} >
                                    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                                        let styles = { ...baseStyle }
                                        styles = isDragActive ? { ...styles, ...activeStyle } : styles
                                        styles = isDragReject ? { ...styles, ...rejectStyle } : styles

                                        return (
                                            <div
                                                {...getRootProps()}
                                                style={styles}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="upload-dropzone-custom">
                                                    {this.state.fileUploadStatus !== "UploadComplete" ?
                                                        <div className="upload-dropzone-custom-div">
                                                            <div>
                                                                {
                                                                    isDragReject ?
                                                                        <FontAwesomeIcon icon={faExclamationCircle} className="upload-reject-icon upload-dropzone-icon" /> :
                                                                        <FontAwesomeIcon icon={faArrowUp} className="upload-dropzone-icon" />
                                                                }
                                                            </div>
                                                            <div>
                                                                <p>{isDragReject && this.state.fileUploadStatus !== "UploadComplete" ? 'Unsupported file type' : 'Drag logo file here to upload'}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="upload-dropzone-preview" style={imagePreviewStyle}>
                                                            <AvatarEditor
                                                                ref={this.setEditorRef}
                                                                image={this.state.fileUploaded}
                                                                width={this.state.editorWidth}
                                                                height={this.state.editorHeight}
                                                                border={0}
                                                                color={[51, 51, 51, 0.3]} // RGBA
                                                                scale={this.state.editorScale}
                                                                rotate={0}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Dropzone>
                                {
                                    this.state.fileUploadStatus === "UploadComplete" ?
                                        <div className="image-scale-slider-container" style={imageScaleStyle}>
                                            <Row className="image-scale-slider-row">
                                                <Col md={2} className="image-scale-container">
                                                    <a href="#" onClick={this.handleImageScaleMinus}>
                                                        <FontAwesomeIcon icon={faMinus} className="image-scale-icon" />
                                                    </a>
                                                </Col>
                                                <Col md={8} className="image-scale-slider-column">
                                                    <p>Scale</p>
                                                    <Slider
                                                      aria-label="Image Scale"
                                                      max={2}
                                                      min={1}
                                                      onChange={this.handleSliderChange}
                                                      size={"small"}
                                                      step={0.1}
                                                      value={this.state.editorScale}
                                                    />
                                                </Col>
                                                <Col md={2} className="image-scale-container">
                                                    <a href="#" onClick={this.handleImageScalePlus}>
                                                        <FontAwesomeIcon icon={faPlus} className="image-scale-icon" />
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                        : ""
                                }
                                {<img src={this.state.fileUploaded} className="image-upload-dummy" onLoad={this.handleDummyImageLoad} />}
                            </div>
                        </Col>
                        <Col md={7} sm={8} className="upload-logo-right">
                            <div className={`upload-error-container ${this.state.fileUploadStatus === "UploadError" ? '' : 'hide-upload-module'}`}>
                                <div className="upload-error-icon-container pull-left">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="upload-error-icon" />
                                </div>
                                <p>The file you uploaded does not meet our minimum requirements outlined below</p>
                            </div>
                            <div>
                                <h4 className="body-title">Logo file requirements</h4>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>Accepted Formats: JPG, PNG</p>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>Max file size: 2MB</p>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>Max Width/Height: 500 px</p>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>Min Width/Height: 200 px</p>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>PPI resolution: 100 ppi</p>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>For best resolution, upload using Chrome</p>
                            </div>
                            <div>
                                <p className={`${this.state.fileUploadStatus === "UploadError" ? 'upload-error-text' : ''}`}>To ensure the logo has been uploaded correctly, please view the PDF at 100% zoom during validation. This will help in identifying any issues with the logo's quality or resolution.</p>
                            </div>
                            <div className={`upload-progress-container ${this.state.fileUploadStatus === "Uploading" ? '' : 'hide-upload-module'}`}>
                                <p>{this.state.fileName}</p>
                                <div>
                                    <i>Uploading</i>
                                    <ProgressBar active now={100} />
                                </div>
                            </div>
                            <div className={`upload-complete-container ${this.state.fileUploadStatus === "UploadComplete" ? '' : 'hide-upload-module'}`}>
                                <Row>
                                    <Col md={2} sm={3} className="upload-complete-icon-container">
                                        <FontAwesomeIcon icon={faCheckCircle} className="upload-complete-icon" />
                                    </Col>
                                    <Col md={10} sm={9} className="upload-complete-content-container">
                                        <h5 className="body-title">File Uploaded</h5>
                                        <p>Please scale logo as needed and click “Save” to continue</p>
                                        <a href="#" className="upload-complete-different" onClick={this.handleNewFileUpload}>Upload Different File</a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleAddLogoPopupSave} className={`${this.state.fileUploadStatus === "UploadComplete" ? '' : 'disabled'}`}>Save</Button>
                </Modal.Footer>
            </Modal>
		)
    }
}

function mapStateToProps(state) {
    return {
        userLogo: state.headerReducer.userLogo,
        whiteLabelledPdf: state.whiteLabelPdfReducer.whiteLabelledPdf,
        whiteLabelPdfPendingUserLogo: state.whiteLabelPdfReducer.whiteLabelPdfPendingUserLogo,
        openUploadLogoFromPreviewFlag: state.previewReducer.openUploadLogoFromPreviewFlag,
        refreshUserLogo: state.headerReducer.refreshUserLogo,
        previewPdfPendingUserLogo: state.previewReducer.previewPdfPendingUserLogo,
        previewDocId: state.tabsReducer.previewDocId,
        downloadAllReportModalOpen: state.whiteLabelPdfReducer.downloadAllReportModalOpen,
        renderingQueueReports: state.renderingQueueReducer.renderingQueueReports,
        uploadLogoAndDownloadAll: state.whiteLabelPdfReducer.uploadLogoAndDownloadAll,
        whiteLabelledPdfFileName: state.whiteLabelPdfReducer.whiteLabelledPdfFileName
    }
}

function mapDispatchToProps(dispatch) {
    return {
        requestGetUserLogoPref: () => {
            return dispatch(actions.requestGetUserLogoPref())
        },
        requestPutUserLogoPref: (inputParams) => {
            return dispatch(actions.requestPutUserLogoPref(inputParams))
        },
        requestGetWhiteLabelledPdf: (docId) => {
            return dispatch(requestGetWhiteLabelledPdf(docId))
        },
        openUploadLogoFromPreview: (inputParams) => {
            return dispatch(openUploadLogoFromPreview(inputParams))
        },
        requestWhiteLabelDoc: (docId) => {
            return dispatch(requestWhiteLabelDoc(docId))
        },
        clearWhiteLabelPdf: () => {
            return dispatch(clearWhiteLabelPdf())
        },
        downloadAllAddToQueue: (docIds) => {
            return dispatch(downloadAllAddToQueue(docIds))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadLogoPopupModal)
