/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import TABS_STATE from "./initial-state/tabsState";

const openPreviewTab = (state, action) =>
	Object.assign({}, state, {
		previewDocId: action.tabParams.previewDocId,
		activeTab: action.tabParams.activeTab,
		previewHeadline:  action.tabParams.previewHeadline,
		previewSubject: action.tabParams.previewSubject
	});

const switchTabs = (state, action) =>
	Object.assign({}, state, {
		activeTab: action.tabKey
	});
 const openRequestedReportPreviewTab = (state, action) =>
	Object.assign({}, state, {
        previewDocId: action.response.data.document.documentId,
		activeTab: 2,
		previewHeadline: action.response.data.document.headline,
		previewSubject: action.response.data.document.subject
	});
const ACTION_HANDLERS = {
	[Types.OPEN_PREVIEW_TAB]: openPreviewTab,
    [Types.SWITCH_TABS]: switchTabs,
    [Types.OPEN_REQUESTED_REPORT_PREVIEW_TAB]:openRequestedReportPreviewTab
};

export default createReducer(TABS_STATE, ACTION_HANDLERS);
