/* eslint-disable */
import Raven from 'raven-js';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import rootReducer from '../reducers/rootReducers';
import rootSaga from '../sagas/RootSaga';

let sentryDsn = '';
const DEV_SENTRY_DSN = 'http://c6f725c794674ce48dc8f945b90f5a5f@sentry.dev.local/20';
const ACC_SENTRY_DSN = 'https://629fa21adf1d435fbc67334b4cf115db@logging-api.markitqa.com/16';
const PROD_SENTRY_DSN = 'https://42de5572102149cca56da2d7fea089cc@logging.api.markitondemand.com/6';

switch (window.MD.environment) {
  case 'qa':
    sentryDsn = ACC_SENTRY_DSN;
    break;
  case 'production':
    sentryDsn = PROD_SENTRY_DSN;
    break;
  default:
    sentryDsn = DEV_SENTRY_DSN;
    break;
}
Raven.config(sentryDsn).install();

export default function configureStore() {
  const persistConfig = {
    key: 'root',
    storage : storageSession
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  let myState;
  const sagaMiddleware = createSagaMiddleware({
    onError: function (error) {
      Raven.captureException(error, {
        tags: { type: 'redux-saga' }
      });
    },
    logger: function (level, ...args) {
      if (level === 'error') {
        Raven.captureMessage(args.join(' '), {
          tags: { type: 'redux-saga' }
        });
      }
    }
  });
  const middleware = applyMiddleware(sagaMiddleware);
  const enhancer = compose(middleware);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(persistedReducer, myState, composeEnhancers(enhancer));
  const persistor = persistStore(store);

  // run sagas
  sagaMiddleware.run(rootSaga);

  // Logs state updates when ShowDebugInfo is enabled
  if (window.location.href.indexOf('..showdebuginfo..=on') > -1) {
    store.subscribe(() => {
      console.log('state', JSON.stringify(store.getState()));
    });
  }

  return { store, persistor };
}
