import React from 'react';

import './MonitorPage.scss';

class MonitorPage extends React.Component {
  render() {
    return (
      <div className="monitor-root">
        <div className="ui-message">BlackRock | Brand and Share Tool | Monitor Page</div>
      </div>
    );
  }
}

export default MonitorPage;
