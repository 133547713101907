import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/components/common/ModuleHeader.scss'

export const ModuleHeader = (props) => {
  return (
    <div className='module-header'>
      <span className={props.class}>{ props.title }</span>
    </div>
  )
}

ModuleHeader.propTypes = {
  title: PropTypes.string,
  class: PropTypes.string
}
