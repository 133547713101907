/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import RENDERING_QUEUE_STATE from "./initial-state/renderingQueueState";

const getRenderingQueueReports = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		renderingQueueReports: action.response.data.reportsResponse.reports,
		refreshRenderingQueue: false,
		selectedReports: []
	});

const deleteAllRenderingQueueReports = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		refreshRenderingQueue: true
	});

const postRenderingQueue = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		refreshRenderingQueue: true
	});
	
const deleteSingleRenderingQueueReports = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		refreshRenderingQueue: true
	});

const browseAllAddToSelected = (state, action) =>
	Object.assign({}, state, {
		selectedReports: [...state.selectedReports, action.inputParams.docId]
	});

const browseAllRemoveFromSelected = (state, action) =>
	Object.assign({}, state, {
		selectedReports: state.selectedReports.filter(docId => docId !== action.inputParams.docId)
	});

const ACTION_HANDLERS = {
	[Types.GET_RENDERING_QUEUE]: getRenderingQueueReports,
	[Types.DELETE_ALL_RENDERING_QUEUE]: deleteAllRenderingQueueReports,
	[Types.DELETE_SINGLE_RENDERING_QUEUE]: deleteSingleRenderingQueueReports,
	[Types.POST_RENDERING_QUEUE]: postRenderingQueue,
	[Types.BROWSEALL_ADD_TO_SELECTED]: browseAllAddToSelected,
	[Types.BROWSEALL_REMOVE_FROM_SELECTED]: browseAllRemoveFromSelected
};

export default createReducer(RENDERING_QUEUE_STATE, ACTION_HANDLERS);
