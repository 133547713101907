import _get from 'lodash/get';

const authenticationHelper = {};

authenticationHelper.isAuthenticated = (sessionState) => {
  //let blackrockToken = _get(sessionState, 'blackrock.accessToken');
  let markitToken = _get(sessionState, 'markit.accessToken');

  /**
   * Backwards Compatibility Issue | Routes check authentication before the Saga that
   * loads this key is ready.  So look to see if the ref2token (provided via SAML
   * assertions) meta tag is present.
   */
  if (!markitToken) {
    const element = document.querySelector("meta[name='access_token']");
    if (element) {
      markitToken = element.getAttribute('value');
    }
  }

  // Once backwards compatibility is no longer needed, check the Blackrock.accessToken too!!
  return !!markitToken;
};

export default authenticationHelper;
