import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css';
import Home from './components/HomePage/Home';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import MonitorPage from './components/MonitorPage';
import SignOffPage from './components/SignOffPage';
import SignOnPage from './components/SignOnPage';

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/monitor">
            <MonitorPage />
          </Route>
          <Route path="/signoff">
            <SignOffPage />
          </Route>
          <Route path="/signon">
            <SignOnPage />
          </Route>
          <Route exact path="/">
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
