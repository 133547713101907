/* eslint-disable */
// This is the root reducer in which all other reducers are registered.
// Naming convention is to use index.js
import { combineReducers } from 'redux';

import authenticationReducers from './AuthenticationReducers';
import browseAllReducer from './browseAllReducer';
import commonReducer from './commonReducer';
import headerReducer from './headerReducer';
import previewReducer from './previewReducer';
import renderingQueueReducer from './renderingQueueReducer';
import reportFilterReducer from './reportFilterReducer';
import tabsReducer from './tabsReducer';
import whiteLabelPdfReducer from './whiteLabelPdfReducer';

const rootReducer = combineReducers({
  browseAllReducer,
  commonReducer,
  headerReducer,
  previewReducer,
  renderingQueueReducer,
  reportFilterReducer,
  sessionData: authenticationReducers,
  tabsReducer,
  whiteLabelPdfReducer
});

export default rootReducer;
