/* eslint-disable */
import Types from "./types";

const requestGetHowToModalPref = () => ({
	type: Types.REQUEST_GET_HOW_TO_MODAL_PREF_API,
	endPoint: "/user/howToModalPref",
	ajaxType: "GET",
	onSuccess: responseGetHowToModalPref
});

const requestPutHowToModalPref = inputParams => ({
	type: Types.REQUEST_PUT_HOW_TO_MODAL_PREF_API,
	endPoint: "/user/howToModalPref",
	params:
		inputParams,
	ajaxType: "PUT"
});

const responseGetHowToModalPref = response => ({
	type: (response.data.success === true) ? Types.GET_HOW_TO_MODAL_PREF : Types.GENERIC_FAILURE,
	response
});

const requestGetUserLogoPref = () => ({
	type: Types.REQUEST_GET_USER_LOGO_PREF_API,
	endPoint: "/user/logo",
	ajaxType: "GET",
	onSuccess: responseGetUserLogoPref
});

const requestPutUserLogoPref = inputParams => ({
	type: Types.REQUEST_PUT_USER_LOGO_PREF_API,
	endPoint: "/user/logo",
	params:
		inputParams,
	ajaxType: "PUT",
	onSuccess: responsePutUserLogoPref
});

const responsePutUserLogoPref = response => ({
	type: (response.data.success === true) ? Types.USER_LOGO_PREF_UPDATE : Types.GENERIC_FAILURE,
	response
});

const responseGetUserLogoPref = response => ({
	type: (response.data.success === true) ? Types.GET_USER_LOGO_PREF : Types.GENERIC_FAILURE,
	response
});

const openHowToModal = inputParams => ({
	type: Types.OPEN_HOW_TO_MODAL,
	inputParams
});

export {
	requestGetHowToModalPref,
	requestPutHowToModalPref,
	requestGetUserLogoPref,
	requestPutUserLogoPref,
	openHowToModal
};