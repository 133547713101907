/* eslint-disable */
import Types from "./types";

const requestWhiteLabelDoc = docId => ({
	type: Types.REQUEST_GET_WHITELABEL_DOC_API,
	endPoint: "/whiteLabelDoc/" + docId + '/false',
	ajaxType: "GET",
	onSuccess: responseGetWhiteLabelDoc
});

const responseGetWhiteLabelDoc = response => ({
	type: (response.data.success === true) ? Types.GET_WHITELABEL_DOC : Types.GENERIC_FAILURE,
	response
});

const openUploadLogoFromPreview = inputParams => ({
	type: Types.OPEN_UPLOAD_LOGO_MODAL_FROM_PREVIEW,
	inputParams
});

const clearPreviewWhiteLabelPdf = () => ({
	type: Types.CLEAR_PREVIEW_WHITELABELDOC
});

export {
	requestWhiteLabelDoc,
	openUploadLogoFromPreview,
	clearPreviewWhiteLabelPdf
};