/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import * as actions from '../../actions/whiteLabelPdfActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleNotch,faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { openUploadLogoFromPreview } from '../../actions/previewActions'
import '../../styles/components/common/DownloadAllModal.scss'

class DownloadAllModal extends React.Component {
	constructor(props) {
		super(props);
        this.handleDownloadAllReportModalClose = this.handleDownloadAllReportModalClose.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps && nextProps.whiteLabelledPdf && nextProps.whiteLabelledPdf != "" && this.props.downloadAllReportModalOpen) {
			let linkSource = nextProps.whiteLabelledPdf;
			let fileName = nextProps.whiteLabelledPdfFileName + '.pdf';

			// IE workaround to download pdf file
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				var byteCharacters = atob(linkSource.replace("data:application/pdf;base64,", ""));
				var byteNumbers = new Array(byteCharacters.length);
				for (var i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				var byteArray = new Uint8Array(byteNumbers);
				var blob = new Blob([byteArray], {type: 'application/pdf'});
				window.navigator.msSaveOrOpenBlob(blob, fileName);
			}
			else { // sudo element download approach for downlaoding pdf
				let downloadLink = document.createElement("a");
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}

			let downloadedDocId = this.props.whiteLabelledPdfID;
			this.props.clearWhiteLabelPdf();
			this.props.downloadAllRemoveDownloaded(downloadedDocId);
		}
		if(nextProps && nextProps.downloadAllReportIds && nextProps.downloadAllReportIds.length > 0 && this.props.downloadAllReportIds.length > 0 && nextProps.downloadAllReportIds != this.props.downloadAllReportIds) {
			if(nextProps.downloadAllReportIds.length > 0) {
				let currentDocId = nextProps.downloadAllReportIds[0];

				if (currentDocId !== "" && currentDocId !== undefined) {
                    this.props.requestGetWhiteLabelledPdf(currentDocId);
				}
			}
		}
	}

	handleDownloadAllReportModalClose() {
		if (this.props.downloadAllReportIds.length > 0) {
			return false;
		}
		else {
			this.props.downloadAllComplete();
		}
	}

	render() {
			return (
				<Modal show={this.props.downloadAllReportModalOpen} onHide={this.handleDownloadAllReportModalClose} keyboard={false} className="blk-modal downloadAllReportModal">
					<Modal.Header closeButton={this.props.downloadAllReportIds.length > 0 ? false : true}>
						<Modal.Title></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="download-progress-icon-container">
							{
								this.props.whiteLabelledPdfFailIds && this.props.whiteLabelledPdfFailIds.length > 0 && this.props.downloadAllReportIds.length < 1 ?
                                <FontAwesomeIcon icon={faExclamationCircle} className="download-failed-icon" />
                                : this.props.downloadAllReportIds.length > 0 ?
								<FontAwesomeIcon icon={faCircleNotch} className="download-progress-icon" spin/>
                                :
								<FontAwesomeIcon icon={faCheckCircle} className="download-complete-icon" />
							}
						</div>
						<div className="download-success-header">
                            <h4>{this.props.whiteLabelledPdfFailIds && this.props.whiteLabelledPdfFailIds.length > 0 && this.props.downloadAllReportIds.length < 1 ?
                              'Download Error'
                            : this.props.downloadAllReportIds.length > 0 ? 'Processing Reports'
                            : 'Download complete!' }
                            </h4>
						</div>
						<div className="download-message">
                            <p>{this.props.whiteLabelledPdfFailIds && this.props.whiteLabelledPdfFailIds.length > 0 && this.props.downloadAllReportIds.length < 1 ?
                              this.props.whiteLabelledPdfFailIds.length+' file(s) failed to download. Check your downloads folder to view your customized document(s).'
                            : 'Nice work. You’re ready to rock for your next client meeting or communication. Check your downloads folder to view your customized document(s).'}</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={this.handleDownloadAllReportModalClose} className={`${this.props.downloadAllReportIds.length > 0 ? 'disabled' : ''}`}>Return To Tool</Button>
						<p className="termsOfUse">As a reminder, You, as a user of this tool, have agreed to and are responsible for ensuring you are sharing the latest content with your clients. Please check back regularly to ensure content remains available and up to date. The Terms of Use in effect for this and other Advisor Center tools are available <a target="_blank" href="https://www.blackrock.com/us/financial-professionals/compliance/advisor-terms-of-use#brandandshare">here</a>.</p>
					</Modal.Footer>
				</Modal>
			)
		}
}

function mapStateToProps(state) {
	return {
		downloadAllReportModalOpen: state.whiteLabelPdfReducer.downloadAllReportModalOpen,
		downloadAllReportIds: state.whiteLabelPdfReducer.downloadAllReportIds,
		downloadAllReportIdsTotalCount: state.whiteLabelPdfReducer.downloadAllReportIdsTotalCount,
		whiteLabelledPdfFileName: state.whiteLabelPdfReducer.whiteLabelledPdfFileName,
		whiteLabelledPdf: state.whiteLabelPdfReducer.whiteLabelledPdf,
        whiteLabelledPdfID: state.whiteLabelPdfReducer.whiteLabelledPdfID,
        whiteLabelledPdfFailIds: state.whiteLabelPdfReducer.whiteLabelledPdfFailIds
	}
}

function mapDispatchToProps(dispatch) {
	return {
		downloadAllRemoveDownloaded: (docId) => {
			return dispatch(actions.downloadAllRemoveDownloaded(docId))
		},
		clearWhiteLabelPdf: () => {
			return dispatch(actions.clearWhiteLabelPdf())
		},
		requestGetWhiteLabelledPdf: (docId) => {
			return dispatch(actions.requestGetWhiteLabelledPdf(docId))
		},
		downloadAllComplete: () => {
			return dispatch(actions.downloadAllComplete())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAllModal)
