/* eslint-disable */
import React from 'react'
import '../../styles/components/common/Loader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

export const Loader = (props) => {
  return (
    <div id="cover-spin">
      <FontAwesomeIcon className="loader" icon={faCircleNotch} spin/>
    </div>
  )
}
