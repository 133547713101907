import PKCE from 'js-pkce';
import React from 'react';
import _get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './SignOnPage.scss';
import authenticationApi from '../../services/AuthenticationApi';
import authenticationHelper from '../../utils/AuthenticationHelper';
import spinner from '../../images/spinner.gif';
import { updateSession } from '../../actions/AuthenticationActions';

class SignOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticationFailed: false,
      /**
      * The implementation of Authorization Code Flow + PKCE is an OpenId Connect flow
      * specifically designed to authenticate native or mobile application users. This
      * flow is considered best practice when using Single Page Apps (SPA) or Mobile
      * Apps. PKCE, is an acronym for Proof Key for Code Exchange.
      */
      pkce: new PKCE({
        authorization_endpoint: `${window.MD.authentication.blackrock.issuerUrl}/v1/authorize`,
        client_id: window.MD.authentication.blackrock.clientId,
        redirect_uri: window.MD.authentication.blackrock.redirectUrl,
        requested_scopes: 'openid',
        //storage: localStorage,
        token_endpoint: `${window.MD.authentication.blackrock.issuerUrl}/v1/token`
      })
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      isAuthenticated: authenticationHelper.isAuthenticated(props.session)
    };
  }

  async componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const authCode = searchParams.get('code');

    // Authenication Callback
    if (authCode) {

      // Checks the hostname for changes so that authentication can be reset.
      if ((sessionStorage.hostname || '').toLowerCase() !== (window.location.hostname || '').toLowerCase()) {
        console.log('hostname change on redirect has caused authentication to start over.')
        window.location.replace(`${window.MD.authentication.blackrock.redirectUrl}/signon`); // Removes querystring
        return;
      }

      // Swaps the auth code for an access token.
      const blackrockToken = await this.state.pkce.exchangeForAccessToken(window.location.href);

      if (blackrockToken && blackrockToken.access_token && blackrockToken.id_token) {
        // Exchanges the provided Blackrock token for a Markit token.
        const tokenExchange = await authenticationApi
          .create({
            baseURL: window.MD.apiBaseUrl,
            provider: authenticationApi.Providers.MARKIT
          })
          .postData({
            endPoint: '/public/token-exchange/',
            parameters: new URLSearchParams({
              accessToken: blackrockToken.access_token,
              idToken: blackrockToken.id_token
            }).toString()
          });
        const markitToken = _get(tokenExchange, 'data.data.markit');

        this.props.updateSession({
          blackrock: blackrockToken,
          markit: markitToken
        });

      } else {
        this.setState({ isAuthenticationFailed: true });
      }

      return;
    }

    // Authenication Start
    if (!this.state.isAuthenticated) {

      // Keeps track of the hostname before authenication starts.
      sessionStorage.hostname = window.location.hostname;

      // Drafts the auth request URL.
      const authorizeUrl = this.state.pkce.authorizeUrl({
        disable_registration: 'true',
        issuer_uri: window.MD.authentication.blackrock.issuerUrl,
        nonce: 'null',
        response_type: 'code',
        site: 'tools',
        state: 'null'
      });

      // Checks for an active session.
      const sessions = await authenticationApi
        .create({
          baseURL: new URL(window.MD.authentication.blackrock.issuerUrl).origin,
          provider: authenticationApi.Providers.BLACKROCK
        })
        .getData({ endPoint: '/api/v1/sessions/me' });

      switch (sessions.status) {
        case 200:
          // Request current session.
          window.location.replace(authorizeUrl);
          break;
        case 404:
        default:
          // Redirects to login.
          window.location.replace(
            `${window.MD.authentication.blackrock.loginUrl}${new URL(authorizeUrl).search}`
          );
          break;
      }
    }
  }

  render() {
    const isDev = ['development', 'localhost'].indexOf(window.MD.environment) > -1;

    return (
      <div className="signon-root">
        {isDev || this.state.isAuthenticated ? (
          <>
            <Redirect
              to={{
                pathname: '/'
              }}
            />
          </>
        ) : this.state.isAuthenticationFailed ? (
          <div className="auth-error">
            <p className="center-text">Unable to login at this time.</p>
            <div className="center-text">
              <a href={window.MD.logoUrl}>Return to BlackRock</a>
            </div>
          </div>
        ) : (
          <div className="spinner-container">
            <img alt={'Loading'} className={'spinner'} src={spinner} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.sessionData
});

const mapDispatchToProps = (dispatch) => ({
  updateSession: (response) => dispatch(updateSession(response))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignOn);
