/* eslint-disable */
import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import '../../styles/components/RenderingQueue.scss'
import '../../styles/components/common/Buttons.scss'
import * as actions from '../../actions/renderingQueueActions'
import {downloadAllAddToQueue, requestGetWhiteLabelledPdf, uploadLogoAndDownloadAllCall,clearWhiteLabelPdfFailCount} from '../../actions/whiteLabelPdfActions'
import { connect } from 'react-redux'
import RenderingQueueItem from './RenderingQueueItem'
import { openUploadLogoFromPreview } from '../../actions/previewActions'

class RenderingQueue extends React.Component {
	constructor(props) {
		super(props);
		this.handleRemoveAll = this.handleRemoveAll.bind(this);
		this.handleDownloadAll = this.handleDownloadAll.bind(this);
	}

	componentDidMount() {
		this.props.requestGetRenderingQueue();
	}

	handleRemoveAll() {
		this.props.requestDeleteAllRenderingQueue();
	}

	handleDownloadAll() {
        this.props.clearWhiteLabelPdfFailCount();
		if(this.props.renderingQueueReports.length > 0) {
			if (this.props.userLogo && this.props.userLogo != '') {
				let docIds = {
					documents: this.props.renderingQueueReports.map(report => report.documentId)
				};
				this.props.downloadAllAddToQueue(docIds);

				let currentDocId = docIds.documents[0];

				if (currentDocId !== "" && currentDocId !== undefined) {
					this.props.requestGetWhiteLabelledPdf(currentDocId);
				}
			}
			else {
				this.props.uploadLogoAndDownloadAllCall();
				const inputParams = {
					openUploadLogoFromPreviewFlag: true
				}
				this.props.openUploadLogoModal(inputParams);
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps && nextProps.refreshRenderingQueue) {
			this.props.requestGetRenderingQueue();
		}
	}

	render() {
		return (
			<div className="pdfqueue-main">
				<div className="pdfqueue-header">
					My Selection
					{this.props.renderingQueueReports && this.props.renderingQueueReports.length > 0
						? <span className="pdfqueue-badge">{this.props.renderingQueueReports.length}</span>
						: ""
					}
				</div>
				<Button className={`button button-black renderAllButton ${this.props.renderingQueueReports && this.props.renderingQueueReports.length > 0 ? '' : 'disabled'}`} onClick={this.handleDownloadAll}>
					<span>Download All</span>
					<FontAwesomeIcon icon={faAngleRight} />
				</Button>
				<div className="renderingQueueButtons">
					<a href="#" className={`removeQueueButton ${this.props.renderingQueueReports && this.props.renderingQueueReports.length > 0 ? '' : 'disabled'}`} onClick={this.handleRemoveAll}>
						<FontAwesomeIcon icon={faTimes} className={`removeQueueIcon ${this.props.renderingQueueReports && this.props.renderingQueueReports.length > 0 ? '' : 'disabled'}`} />
						Remove All
					</a>
				</div>
				{this.props.renderingQueueReports && this.props.renderingQueueReports.length > 0
					? <hr/>
					: <p className="renderingQueueZeroStateTxt"><i>You have not yet added any PDFs to your queue. Select one or more documents on the right to add to PDF queue.</i></p>
				}
				{this.props.renderingQueueReports && this.props.renderingQueueReports.length > 0
					? this.props.renderingQueueReports.map((report, index) => <RenderingQueueItem key={index} {...report} />)
					: ""
				}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		renderingQueueReports: state.renderingQueueReducer.renderingQueueReports,
		refreshRenderingQueue: state.renderingQueueReducer.refreshRenderingQueue,
        userLogo: state.headerReducer.userLogo
	}
}

function mapDispatchToProps(dispatch) {
	return {
		requestGetRenderingQueue: () => {
			return dispatch(actions.requestGetRenderingQueue())
		},
		requestDeleteAllRenderingQueue: () => {
			return dispatch(actions.requestDeleteAllRenderingQueue())
		},
		downloadAllAddToQueue: (docIds) => {
			return dispatch(downloadAllAddToQueue(docIds))
		},
		requestGetWhiteLabelledPdf: (docId) => {
			return dispatch(requestGetWhiteLabelledPdf(docId))
		},
		openUploadLogoModal: (inputParams) => {
			return dispatch(openUploadLogoFromPreview(inputParams));
		},
		uploadLogoAndDownloadAllCall: () => {
			return dispatch(uploadLogoAndDownloadAllCall())
        },
        clearWhiteLabelPdfFailCount: () => {
			return dispatch(clearWhiteLabelPdfFailCount())
        }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RenderingQueue)
