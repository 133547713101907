import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/components/common/Headline.scss'

export const HeadLineTitle = (props) => {
  return (
    <p className='headline headline-title'>
      { props.title }
    </p>
  )
}

export const HeadLineSubject = (props) => {
  return (
    <p className='headline headline-subject'>
      { props.subject }
    </p>
  )
}

HeadLineTitle.propTypes = {
  title: PropTypes.string
}
HeadLineSubject.propTypes = {
  subject: PropTypes.string
}
