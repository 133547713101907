/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import HEADER_STATE from "./initial-state/headerState";

const getHowToModalPref = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		howToModalCount: action.response.data.howToModalCount
	});

const getUserLogoPref = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		userLogo: action.response.data.userLogo,
		refreshUserLogo: false
	});

const updateUserLogoPref = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		refreshUserLogo: true
	});

const openHowToModal = (state, action) =>
	Object.assign({}, state, {
		openHowToModalFlag: action.inputParams.openHowToModalFlag,
	});

const ACTION_HANDLERS = {
	[Types.GET_HOW_TO_MODAL_PREF]: getHowToModalPref,
	[Types.GET_USER_LOGO_PREF]: getUserLogoPref,
	[Types.USER_LOGO_PREF_UPDATE]: updateUserLogoPref,
	[Types.OPEN_HOW_TO_MODAL]: openHowToModal
};

export default createReducer(HEADER_STATE, ACTION_HANDLERS);