/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import '../../styles/components/common/errorTemplate.scss'
import { resetFetchingFlag } from '../../actions/commonActions'

class ErrorTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      timeoutId: null
    }
    this.hideError = this.hideError.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.fetching === false && this.props.activeTab === this.props.tabKey) {
      this.setState({
        hasError: true
      });
      if(this.state.timeoutId) {
        clearTimeout(this.state.timeoutId);
        this.setState({timeoutId: setTimeout(this.hideError, 20000)});
      }
      else {
        this.setState({timeoutId: setTimeout(this.hideError, 20000)});
      }
      this.props.resetFetchingFlag()
    }
  }

  hideError() {
    this.setState({ hasError: false })
  }

  render() {
    return (
      <div className={`error-container ${this.state.hasError ? '' : 'hide-error-module'}`}>
        <div className='error-icon-container pull-left'>
          <FontAwesomeIcon icon={faExclamationCircle} className='error-icon' />
        </div>
        <a href="#" className="closeError" onClick={this.hideError}>
          <FontAwesomeIcon icon={faTimes} className="closeErrorIcon" />
        </a>
        <p>
          The tool has encountered an error. Please refresh your browser. If the problem persists, please
          <a>contact us</a>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.commonReducer.fetching,
    activeTab: state.tabsReducer.activeTab
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetFetchingFlag: () => {
      return dispatch(resetFetchingFlag())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorTemplate)
