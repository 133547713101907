import Types from '../actions/types'

function getApiTypes () {
  var apiTypes = []
  var apiReg = /_API$/

  for (var t in Types) {
    if (apiReg.test(t)) {
      apiTypes.push(Types[t])
    }
  }

  return apiTypes
}

export { getApiTypes }
