/* eslint-disable */
const WHITELABEL_PDF_STATE = {
	downloadAllReportModalOpen: false,
	downloadAllReportIds: [],
	downloadAllReportIdsTotalCount: 0,
	whiteLabelledPdfFileName: '',
	whiteLabelledPdf: '',
	whiteLabelledPdfID: '',
	whiteLabelPdfPendingUserLogo: '',
    uploadLogoAndDownloadAll: false,
    whiteLabelledPdfFailIds : []
};
export default WHITELABEL_PDF_STATE;
