/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import PREVIEW_STATE from "./initial-state/previewState";

const getWhiteLabelDoc = (state, action) =>
	Object.assign({}, state, {
		fetching: false,
		error: null,
		whiteLabelledPdf: action.response.data.whiteLabelledPdf,
		previewPdfPendingUserLogo: ''
	});

const openUploadLogoFromPreview = (state, action) =>
	Object.assign({}, state, {
		openUploadLogoFromPreviewFlag: action.inputParams.openUploadLogoFromPreviewFlag,
		previewPdfPendingUserLogo: action.inputParams.docId
	});

const clearPreviewWhiteLabelPdf = (state, action) =>
	Object.assign({}, state, {
		whiteLabelledPdf: ''
	});

const ACTION_HANDLERS = {
	[Types.GET_WHITELABEL_DOC]: getWhiteLabelDoc,
	[Types.OPEN_UPLOAD_LOGO_MODAL_FROM_PREVIEW]: openUploadLogoFromPreview,
	[Types.CLEAR_PREVIEW_WHITELABELDOC]: clearPreviewWhiteLabelPdf,
};

export default createReducer(PREVIEW_STATE, ACTION_HANDLERS);
