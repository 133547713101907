/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import {requestPutHowToModalPref, requestGetHowToModalPref, openHowToModal} from '../../actions/headerActions'
import { Image, Modal, Button, Row, Col } from 'react-bootstrap';
import '../../styles/components/common/Header.scss';
import htm1_logo from '../../images/HTM_1.png';
import htm2_logo from '../../images/HTM_2.png';
import htm3_logo from '../../images/HTM_3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

class HowToPopupModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            howToPopupOpen: false,
            uploadLogoPopupOpen: false
        }
        this.handleHowToPopupClose = this.handleHowToPopupClose.bind(this);
    }

    componentDidMount() {
		this.props.requestGetHowToModalPref();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.howToModalCount === "" && this.props.openHowToModalFlag === false) {
            this.setState({ howToPopupOpen: true });
        }
    }

    handleHowToPopupClose() {
        this.setState({ howToPopupOpen: false });
        const inputParams = {
            howToModalCount: "1"
        };
        this.props.requestPutHowToModalPref(inputParams);
        const openHowToParams = {
			openHowToModalFlag: false
		}
		this.props.openHowToModal(openHowToParams);
    }

    render() {
        return (
            <Modal show={this.state.howToPopupOpen || this.props.openHowToModalFlag} onHide={this.handleHowToPopupClose} keyboard={false} className="blk-modal how-to-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Get Started</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="modal-subheader">
                    The BlackRock Brand & Share Content Library allows you to upload your logo to customize BlackRock’s client-ready content tool with your unique brand.
						</p>
                    <Row>
                        <Col md={4} sm={4}>
                            <div>
                                <p className="how-to-step">Step 1</p>
                            </div>
                            <div>
                                <Image src={htm1_logo} circle />
                                <FontAwesomeIcon icon={faPlay} className="how-to-step-next" />
                            </div>
                            <div>
                                <p>Select one or multiple BlackRock documents to customize with your logo</p>
                            </div>
                        </Col>
                        <Col md={4} sm={4}>
                            <div>
                                <p className="how-to-step">Step 2</p>
                            </div>
                            <div>
                                <Image src={htm2_logo} circle />
                                <FontAwesomeIcon icon={faPlay} className="how-to-step-next" />
                            </div>
                            <div>
                                <p>Upload a new logo or use your existing one if you’ve previously used the tool</p>
                            </div>
                        </Col>
                        <Col md={4} sm={4}>
                            <div>
                                <p className="how-to-step">Step 3</p>
                            </div>
                            <div>
                                <Image src={htm3_logo} circle />
                            </div>
                            <div>
                                <p>Download customized documents to your device and get ready to wow your clients</p>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHowToPopupClose}>Continue To Tool</Button>
                    <p className="termsOfUse">As a reminder, You, as a user of this tool, have agreed to and are responsible for ensuring you are sharing the latest content with your clients. Please check back regularly to ensure content remains available and up to date. The Terms of Use in effect for this and other Advisor Center tools are available <a target="_blank" href="https://www.blackrock.com/us/financial-professionals/compliance/advisor-terms-of-use#brandandshare">here</a>.</p>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        howToModalCount: state.headerReducer.howToModalCount,
        openHowToModalFlag: state.headerReducer.openHowToModalFlag
    }
}

function mapDispatchToProps(dispatch) {
    return {
        requestGetHowToModalPref: () => {
            return dispatch(requestGetHowToModalPref())
        },
        requestPutHowToModalPref: (inputParams) => {
            return dispatch(requestPutHowToModalPref(inputParams))
        },
		openHowToModal: (inputParams) => {
			return dispatch(openHowToModal(inputParams))
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HowToPopupModal)
