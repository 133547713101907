import { createTypes } from 'reduxsauce'

export default createTypes(`
REQUEST_GET_HOW_TO_MODAL_PREF_API
REQUEST_PUT_HOW_TO_MODAL_PREF_API
GET_HOW_TO_MODAL_PREF
SET_HOW_TO_MODAL_PREF
REQUEST_GET_USER_LOGO_PREF_API
REQUEST_PUT_USER_LOGO_PREF_API
GET_USER_LOGO_PREF
SET_USER_LOGO_PREF
USER_LOGO_PREF_UPDATE
SET_ACCESS_TOKEN
GENERIC_FAILURE
REQUEST_GET_REPORTS_API
GET_REPORTS
REQUEST_PUT_RECENT_REPORTS_PREF_API
REQUEST_GET_RECENT_REPORTS_API
GET_RECENT_REPORTS
OPEN_PREVIEW_TAB
SWITCH_TABS
REQUEST_GET_RENDERING_QUEUE_API
GET_RENDERING_QUEUE
REQUEST_POST_RENDERING_QUEUE_API
POST_RENDERING_QUEUE
REQUEST_DELETE_ALL_RENDERING_QUEUE_API
DELETE_ALL_RENDERING_QUEUE
REQUEST_DELETE_SINGLE_RENDERING_QUEUE_API
DELETE_SINGLE_RENDERING_QUEUE
BROWSEALL_ADD_TO_SELECTED
BROWSEALL_REMOVE_FROM_SELECTED
WHITELABEL_PDF_API
WHITELABEL_PDF
UPLOAD_LOGO_AND_WHITELABEL_PDF
REQUEST_GET_WHITELABEL_DOC_API
GET_WHITELABEL_DOC
OPEN_UPLOAD_LOGO_MODAL_FROM_PREVIEW
CLEAR_WHITELABELDOC
DOWNLOAD_ALL_WHITELABELLED_ADD_TO_QUEUE
DOWNLOAD_ALL_WHITELABELLED_REMOVE_DOWNLOADED
DOWNLOAD_ALL_WHITELABELLED_COMPLETE
OPEN_UPLOAD_LOGO_MODAL_DOWNLOAD_ALL
OPEN_HOW_TO_MODAL
WHITELABELLED_PDF_FAIL
CLEAR_WHITELABELLED_PDF_FAIL
RESET_FETCHING_FLAG
GENERIC_SUCCESS
REQUEST_GET_REPORT_BY_ID_API
OPEN_REQUESTED_REPORT_PREVIEW_TAB
CLEAR_PREVIEW_WHITELABELDOC
RESET_LOADING_FLAG
REQUEST_GET_REPORT_FILTERS_API
GET_REPORT_FILTERS
SET_LOADING_FLAG
AUTHENTICATION_CLOSE_SESSION
AUTHENTICATION_UPDATE_SESSION
`)
