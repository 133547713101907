import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import authenticationHelper from '../../utils/AuthenticationHelper';

function PrivateRoute({ children, session }) {
  const isAuthenticated = authenticationHelper.isAuthenticated(session);
  const isDev = ['development', 'localhost'].indexOf(window.MD.environment) > -1;
  const location = useLocation();

  return isDev || isAuthenticated ? (
    children
  ) : (
    <>
      <Redirect
        to={{
          pathname: '/signon',
          search: location.search
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  session: state.sessionData
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
