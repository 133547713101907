/* eslint-disable */
module.exports = {
    browseAll: {
        pageSize: 20,
        marginPagesDisplayed: 2,
        pageRangeDisplayed: 5
    },
    preview: {
        marginPagesDisplayed: 1,
        pageRangeDisplayed: 2
    }
  
}
