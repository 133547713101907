import _get from 'lodash/get';

import Types from './types';

const closeSession = () => {
  return {
    result: {},
    type: Types.AUTHENTICATION_CLOSE_SESSION
  };
};

const updateSession = (params) => {
  return {
    result: {
      blackrock: {
        accessToken: _get(params, 'blackrock.access_token'),
        idToken: _get(params, 'blackrock.id_token')
      },
      markit: _get(params, 'markit', {})
    },
    type: Types.AUTHENTICATION_UPDATE_SESSION
  };
};

export { closeSession, updateSession };
