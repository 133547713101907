/* eslint-disable */
import Types from "./types";

const openPreviewTab = tabParams => ({
	type: Types.OPEN_PREVIEW_TAB,
	tabParams
});

const switchTabs = tabKey => ({
	type: Types.SWITCH_TABS,
	tabKey
});
const requestGetReportByID = docId => ({
	type: Types.REQUEST_GET_REPORT_BY_ID_API,
	endPoint: "/reports/"+docId,
    ajaxType: "GET",
    onSuccess: responseGetReport
});
const responseGetReport = response => ({
	type: (response.data.success === true && response.data.document) ? Types.OPEN_REQUESTED_REPORT_PREVIEW_TAB : Types.GENERIC_FAILURE,
	response
});
export {
	openPreviewTab,
    switchTabs,
    requestGetReportByID
};